import React from 'react'
import './GameCoin.css'


import { useSelector, useDispatch } from 'react-redux'
import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState
} from './../../app/Game'
import { AllApi } from '../../Api/AllApi'
import { GameDB } from '../../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';
import { useState, useRef, useEffect } from 'react';
// import { SetUserLeagueData } from '../../app/Game'
// import { SetMyLeagueRank } from '../../app/Game'
// import { SetLeagueLimitJoinProgress } from '../../app/Game'
// import { SetLeagueMinimumJoinProgress } from '../../app/Game'

// import { SetRewardLeague } from '../../app/Game'
// import { SetMyLeagueRankNumber } from '../../app/Game'
// import { SetGiftTopjoin } from '../../app/Game'
// import { SetNameAccount } from '../../app/Game'
// import { SetJoinCountRedux } from '../../app/Game'
// import { SetPromotionBottomSheet } from '../../app/Game'
// import { SetUserReward } from '../../app/Game'
// import { SetClaimRewardCall } from '../../app/Game'
// import { SetDataClaimReward } from '../../app/Game'
import Promotion from '../../Game/BottomSheets/Promotion'
// import { SetAppSettingData } from '../../app/Game'
import Congratulations from '../../Game/BottomSheets/Congratulations'
import Congratulations2 from '../../Game/BottomSheets/Congratulations2'
import Congratulations3 from '../../Game/BottomSheets/Congratulations3'
import { SetCongratulationsBottomSheet } from './../../app/Game'
import { SetCongratulationsBottomSheet2 } from './../../app/Game'
import { SetCongratulationsBottomSheet3 } from './../../app/Game'
import { Link } from 'react-router-dom';
import InnerGame from './InnerGame'
import Loading from '../../Loading'
import Skeleton from '@mui/material/Skeleton';
import { OnlineRequestAppSettingGame } from '../../GameAction/OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from '../../GameAction/OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from '../../GameAction/OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from '../../GameAction/OnlineRequestTopJoinerGame'
import { CheckDataBaseGame } from '../../GameAction/CheckDataBaseGame'

const GameCoin = () => {
    const dispatch = useDispatch()
    const [MyLeague, setMyLeague] = useState(true)
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const TopJoinerTime = useSelector((state) => state.Game.TopJoinerTime)
    const IsBetweenLeaguge = useSelector((state) => state.Game.IsBetweenLeaguge)

    const [LoadState, setLoadState] = useState(false)
    const [LastUpdate, setLastUpdate] = useState(0)
    const [NoInternet, setNoInternet] = useState(false)
  
    const intervalRef = useRef(null);

    // useEffect(() => {
    //     // console.log(window.navigator.onLine)
    //     if (NoInternet == true) {

    //         const timer = setInterval(async () => {
    //             let IsOnline = window.navigator.onLine
    //             // console.log(IsOnline)
    //             if (IsOnline == true) {
    //                 clearInterval(timer);
    //                 StartGame()

    //             }
    //         }, 10000);
    //         // console.log(NoInternet)
    //     }
    // }, [NoInternet])


  

    // // SnackBar /////////////////////////
    // const [state, setState] = React.useState({
    //     opens: false,
    //     vertical: 'top',
    //     horizontal: 'center',
    // });
    // const { vertical, horizontal, opens } = state;
    // const handleClick = (newState) => {
    //     setState({ ...newState, opens: true });
    // };
    // const handleClose = () => {
    //     setState({ ...state, opens: false });
    // };
    // const [open, setOpen] = useState(false)

    // function onDismiss() {
    //     setTimeout(() => {
    //         setOpen(false)
    //     }, 300);
    // }
    // // SnackBar//////////////////////////////

    // const GetOnlineRequest = async () => {
    //     const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
    //     const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
    //     const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();

    //     let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList()
    //     await OnlineRequestProfileGameClass.OnlineRequestProfile()
    //     await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
    //     // console.log(res)
    //     OfflineRequestAppSetting()
    //     OfflineRequestProfile()
    //     OfflineRequestLeagueList()
    //     dispatch(SetClaimRewardCall(false))
    //     console.log('first')
    // }
    // useEffect(() => {

    //     // console.log(ClaimRewardCall)
    //     if (ClaimRewardCall == true) {
    //         GetOnlineRequest()
    //     }

    //     if (LastUpdate >= 1) {
    //         StartGame()
    //     }
    // }, [ClaimRewardCall])

    // const OfflineRequestAppSetting = async () => {
    //     // console.log('OfflineRequestAppSetting')
    //     let AppSetting = await GameDB.AppSetting.toArray()
    //     if (AppSetting.length > 0) {
    //         // console.log(AppSetting[0])
    //         // console.log(AppSetting[0].AppSetting.leagues)


    //     } else {
    //         // console.log('error app')
    //         // setNoInternet(true)

    //         const timer = setInterval(async () => {
    //             AppSetting = await GameDB.AppSetting.toArray()
    //             // console.log(AppSetting)
    //             if (AppSetting.length == 1) {
    //                 clearInterval(timer);
    //                 OfflineRequestAppSetting()
    //             } else {
    //                 // console.log('error again !!!')
    //             }

    //         }, 1000);
    //     }


    // }
    // const OfflineRequestProfile = async () => {
    //     // console.log('OfflineRequestProfile')
    //     let Profile = await GameDB.Profile.toArray()
    //     // console.log(Profile)
    //     // console.log(Profile.length)
    //     if (Profile.length > 0) {
    //         // console.log(Profile[0])
    //         // dispatch(SetNameAccount(Profile[0].Profile.nameAccount))
    //         dispatch(SetJoinCountRedux(Profile[0].Profile.joinCount))

    //     } else {
    //         // console.log('error')
    //         // setNoInternet(true)
    //         const timer = setInterval(async () => {
    //             Profile = await GameDB.Profile.toArray()
    //             // console.log(AppSetting)
    //             if (Profile.length == 1) {
    //                 clearInterval(timer);
    //                 OfflineRequestProfile()
    //             } else {
    //                 // console.log('error again !!!')
    //             }

    //         }, 1000);
    //     }

    // }

    // const OfflineRequestLeagueList = async () => {
    //     // console.log('OfflineRequestLeagueList')

    //     let LeagueList = await GameDB.LeagueList.toArray()
    //     if (LeagueList.length > 0) {
    //         // console.log(LeagueList[0])
    //         dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
    //         dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))
    //         let LevelUser = false
    //         LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

    //         let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)


    //         let cashTime = LeagueList[0].LeagueList.data.cashTime
    //         let LastTime = LeagueList[0].LastTime
    //         clearInterval(intervalRef.current);
    //         localStorage.setItem('LastTimeLeague', LastTime)
    //         // setLastTime(LastTime)
    //         // setCashTime(cashTime)
    //         // setRunInterval(true) 
    //     } else {
    //         // console.log('error')
    //         // setNoInternet(true)
    //         const timer = setInterval(async () => {
    //             LeagueList = await GameDB.LeagueList.toArray()
    //             // console.log(LeagueList)
    //             if (LeagueList.length == 1) {
    //                 clearInterval(timer);
    //                 OfflineRequestLeagueList()
    //                 // setNoInternet(false)
    //             } else {
    //                 // console.log('error again !!!')
    //             }

    //         }, 1000);

    //     }

    // }
    // const GetUserDataFromAppSetting = async (LevelUser) => {
    //     let AppSetting = await GameDB.AppSetting.toArray()
    //     // console.log(AppSetting)
    //     if (AppSetting.length == 1) {
    //         // console.log(1)
    //         // console.log(AppSetting[0].AppSetting)
    //         dispatch(SetAppSettingData(AppSetting[0].AppSetting))
    //         let RewardArray = AppSetting[0].AppSetting.leagues

    //         if (LevelUser != false) {
    //             RewardArray.map(item => {

    //                 if (item.leagueLevel == LevelUser) {

    //                     dispatch(SetRewardLeague(item))
    //                 }
    //             })
    //             dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

    //             let LeaguesArray = AppSetting[0].AppSetting.leagues
    //             LeaguesArray.map(item => {
    //                 if (item.leagueLevel == LevelUser) {
    //                     dispatch(SetMyLeagueRank(item.leagueName))
    //                     dispatch(SetLeagueLimitJoinProgress(item.max))
    //                     dispatch(SetLeagueMinimumJoinProgress(item.min))

    //                     dispatch(SetMyLeagueRankNumber(item.leagueLevel))

    //                 }
    //             })
    //         }
    //     } else {
    //         // console.log(2)
    //         const timer = setInterval(async () => {
    //             AppSetting = await GameDB.AppSetting.toArray()
    //             // console.log(AppSetting)
    //             if (AppSetting.length == 1) {
    //                 clearInterval(timer);
    //                 GetUserDataFromAppSetting(LevelUser)
    //             } else {
    //                 // console.log(147852)
    //             }

    //         }, 1000);

    //     }


    // }
    // const GetleagueListJoinsfunc = async () => {
    //     let Data;
    //     const MakeApiClass = new AllApi();
    //     let Result = await MakeApiClass.GetleagueListJoins()
    //     // console.log(Result)

    //     if (Result.code == 200) {
    //         Data = Result

    //     } else if (Result.code == 301) {
    //         Data = 'Name'

    //     } else if (Result.code == 999) {
    //         Data = 'Network'
    //     }
    //     return Data
    // }
    // const CheckForClaimReward = async () => {

    //     let JR = JoinCountRedux
    //     // let JR = 150
    //     let AppSetting = await GameDB.AppSetting.toArray()
    //     let Profile = await GameDB.Profile.toArray()

    //     let LeagueLevelProfile
    //     if (Profile.length > 0) {

    //         LeagueLevelProfile = Profile[0].Profile.leagueLevel
    //     }
    //     if (AppSetting.length > 0) {

    //         let LeaguesAppSetting = AppSetting[0].AppSetting.leagues
    //         // console.log(LeaguesAppSetting)
    //         LeaguesAppSetting.map((item, i) => {
    //             let min = item.min
    //             let max = item.max ? item.max : false
    //             let PrevIndex
    //             if (LeagueLevelProfile == item.leagueLevel) { PrevIndex = i }

    //             if (min <= JR && JR < max) {

    //                 if (LeagueLevelProfile == item.leagueLevel) {
    //                     // no need to claim reward
    //                 } else {
    //                     // need to claim reward
    //                     if (item.leagueLevel - LeagueLevelProfile > 0) {
    //                         let Comparelevel = item.leagueLevel - LeagueLevelProfile
    //                         dispatch(SetUserReward(item))
    //                         let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }

    //                         dispatch(SetDataClaimReward(dataClaim))
    //                         dispatch(SetPromotionBottomSheet(true))
    //                     }

    //                 }
    //             } else if (min <= JR && max == false) {
    //                 if (LeagueLevelProfile == item.leagueLevel) {
    //                     // no need to claim reward
    //                 } else {
    //                     // need to claim reward
    //                     if (item.leagueLevel - LeagueLevelProfile > 0) {
    //                         let Comparelevel = item.leagueLevel - LeagueLevelProfile
    //                         dispatch(SetUserReward(item))
    //                         let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
    //                         dispatch(SetDataClaimReward(dataClaim))
    //                         dispatch(SetPromotionBottomSheet(true))
    //                     }

    //                 }
    //             }
    //         })


    //     }
    // }
    // useEffect(() => {
    //     CheckForClaimReward()
    // }, [JoinCountRedux])

//     useEffect(() => {
    
//         // StartGame()  
  
//  }, [])
//     const StartGame = async () => {
//         const CheckDataBaseGameClass = new CheckDataBaseGame();
//         await CheckDataBaseGameClass.CheckDataBase()
//     }



    // useEffect(() => {
    //     // OfflineRequestAppSetting()
    //     // OfflineRequestProfile()
    //     // OfflineRequestLeagueList()

    //     return () => {

    //     }
    // }, [])




    return (

        !LoadState ?
            <div className='GameCoin'>
                <Link to="/Game" className='GameCoinContainer'>

                    <InnerGame />
                </Link>
               
            </div>
            : <Skeleton variant="rounded" className="SkeletonInCoinGame" width={210} height={60} />



    )
}

export default GameCoin