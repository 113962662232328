import React from 'react'
export class CalcTimeStartGame extends React.Component {
    constructor() {
        super();
    }
   async CalcTimeStart(time) {
    // console.log(time)
        let NowInNow = new Date().getTime();
        let Distance = time - NowInNow;
        var minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((Distance % (1000 * 60)) / 1000);
        minutes = String(minutes).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");
        return { minutes, seconds }  
    }


}
