import React from 'react'
import { useState } from 'react'
import Logo1 from './../Public/Image/Logo.svg'
import Logo2 from './../Public/Image/Logo1.svg'
import Logo3 from './../Public/Image/Logo2.svg'
import Logo4 from './../Public/Image/Logo3.svg'
import './Slider.css'
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment, SetValue } from './../app/Slice'

const Slider = () => {
    const count = useSelector((state) => state.counter.value)
    const dispatch = useDispatch()
    const [touchStartX, setTouchStartX] = useState(null);
    const [touchEndX, setTouchEndX] = useState(null);
    const [startX, setStartX] = useState(0);
    const [offsetX, setOffsetX] = useState(0);
    const [className, setclassName] = useState('SliderContainerRight')
    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
        setStartX(e.touches[0].clientX);
    }
 
    const handleTouchMove = (e) => {
        setTouchEndX(e.touches[0].clientX);
        const newOffsetX = e.touches[0].clientX - startX;
        setOffsetX(newOffsetX);
    };
    const handleTouchEnd = () => {
        if (touchStartX !== null && touchEndX !== null) {
            const diff = touchEndX - touchStartX;
            if (diff > 50) {
                // Swipe right
                dispatch(decrement())
                setclassName('slideInFromLeft')

            } else if (diff < -50) {
                // Swipe left
                dispatch(increment())
                setclassName('slideInFromRight')

            }
        }
        setTouchStartX(null);
        setTouchEndX(null);

        setStartX(null);
        setOffsetX(0);
    };
    const setSliderByPoint = (value) => {
        dispatch(SetValue(value))
    }
    let arr = [
        {
            image: Logo1, header: 'Welcome to Membersgram', des: 'Order member and view for your channels and groups'
        },
        {
            image: Logo2, header: 'Increase member', des: 'Order as many members as you want for your channels and groups'
        },
        {
            image: Logo3, header: 'View increase', des: 'The number of ordered members will be recorded on the last 5 posts'
        },
        {
            image: Logo4, header: 'Get coins', des: 'By adding any Telegram account, you can receive free coins'
        }
    ]


    
    return (
        <div className='SliderContainer'



        >
            
            {/* img and textt */}

            <div className={'SliderContainer'} id='' onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                >
                <div style={{ height: "155px" }}>
                    <img id="ImageViewer" src={arr[count].image}
                        style={{ transform: `translateX(${offsetX}px)` }} className='slidable-element' />

                </div>
                <h1 style={{ transform: `translateX(${offsetX}px)` }} className={count === 0 ? 'SliderHeader24 slidable-element' : 'SliderHeader20 slidable-element'}  >
                    {
                        arr[count].header
                    }

                </h1>
                <p className='SliderDescription slidable-element' style={{ transform: `translateX(${offsetX}px)` }}>
                    {
                        arr[count].des
                    }
                </p>
            </div>

            {/* img and textt */}

            <div className='SliderIndicator'>
                <div className='IndicatorContainer'>
                    <div onClick={() => setSliderByPoint(0)} className={count === 0 ? 'IndicatorActive' : 'Indicator'}></div>
                    <div onClick={() => setSliderByPoint(1)} className={count === 1 ? 'IndicatorActive' : 'Indicator'}></div>
                    <div onClick={() => setSliderByPoint(2)} className={count === 2 ? 'IndicatorActive' : 'Indicator'}></div>
                    <div onClick={() => setSliderByPoint(3)} className={count === 3 ? 'IndicatorActive' : 'Indicator'}></div>
                </div>

            </div>
        </div>
    )
}

export default Slider