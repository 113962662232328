import React from 'react'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import BackImg from './../Public/Image/Login/Back.svg'
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import { Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
// import { ChangePasswordApi } from './../Api/ChangePasswordApi'
import { AllApi } from './../Api/AllApi'
import { setTooManyLogin } from './../app/Home'
import TooManyRequestBSh from './../BottomSheet/TooManyRequestBSh'

import Snackbar from '@mui/material/Snackbar';
// import { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setMiniLoading } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
const ChangePassword = () => {
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const dispatch = useDispatch()
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get('key');
    const Email = searchParams.get('email');

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');

    const handlePasswordChange = (event) => {
        setError1(false)
        setPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setError2(false)
        setConfirmPassword(event.target.value)
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setConfirmShowPassword(!showConfirmPassword);
    };
    const [Error1, setError1] = useState(false)
    const [Error2, setError2] = useState(false)
    const CheckPassword = async () => {
        dispatch(setMiniLoading(true))

        let CheckPass1 = false
        let CheckPass2 = false

        if (password !== '') {
            if (password.length > 7) {
                CheckPass1 = true
                setError1(false)
            } else {
                setError1('password must be at least 8 characters')
            }
        } else {
            setError1('password must not be empty')
        }

        if (ConfirmPassword !== '') {
            if (ConfirmPassword.length > 7) {
                CheckPass2 = true
                setError2(false)
            } else {
                setError2('password must be at least 8 characters')

            }
        } else {
            setError2('password must not be empty')
        }
        if (CheckPass1 && CheckPass2) {
            if (ConfirmPassword === password) {
                setError2(false)
                const MakeApiClass = new AllApi();
                let res = await MakeApiClass.ChangePasswordApi(Email, key, password)
                // let res = await ChangePasswordApi(Email, key, password)
                if (res.code == 429) {
                    dispatch(setTooManyLogin(true)) 
                }
               else if (res.code === 200) {
                    // snackbar
                    handleClick({ vertical: 'bottom', horizontal: 'center' })
                    setTimeout(() => {
                        history.push(`/Login`);
                    }, 2000);


                } else if (res.code === 300) {
                    //             code  300 : Invalid value  
                } else if (res.code === 301) {
                    // code  301 :کاربری با این ایمیل یافت نشد 
                } else if (res.code === 302) {
                    // Code 302 : کلید وارد شده اشتباه است
                    setError2('Invalid value')

                } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                    let name = "Token"
                    let name1 = "active"
                    document.cookie = name + '=; Max-Age=-99999999;';
                    document.cookie = name1 + '=; Max-Age=-99999999;';
                    history.push("/");
                    // dispatch(setLoading(false))

                }




            } else {
                setError2('passwords do not match')
            }

        }
        setTimeout(() => {
            dispatch(setMiniLoading(false))
        }, 1000);


    }

    return (
        <div className='ForgetPasswordContainer'>
            <div className='ForgetPasswordHeader'>
                <Link to="/ForgetPassword" className='ForgetPasswordBackIcon'>
                    <img alt='back' src={BackImg} />
                </Link>
                <div className='ForgetPasswordHeaderText'>
                    ChangePassword
                </div>
            </div>
            <div className='ForgetPasswordBody'>
                <div className='ForgetPasswordBodyText'>
                    Please enter new password and confirm it
                </div>
                <div className='LoginEmailInput'>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={opens}
                        onClose={handleClose}
                        // message={` Password changed`}
                        key={vertical + horizontal}
                        autoHideDuration={3000}
                        message={<span id="client-snackbar"><CheckCircleIcon className='CheckIcon' /> Password changed</span>}

                    />
                    {
                        Error1 === 'password must be at least 8 characters' ?
                            <TextField
                                error
                                fullWidth
                                variant="outlined"
                                className='RegisterPasswordTextField'
                                label="New password"
                                id="outlined-error-helper-text"
                                helperText="Password must be at least 8 characters"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handlePasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        password.length > 0 ?
                                            <InputAdornment position="end" className='IconShowHideError'>
                                                <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                                                    {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                </IconButton>
                                            </InputAdornment>
                                            : null
                                    ),
                                }}
                            />
                            :
                            Error1 === 'password must not be empty' ?
                                <TextField
                                    error
                                    fullWidth
                                    variant="outlined"
                                    className='RegisterPasswordTextField'
                                    label="New password"
                                    id="outlined-error-helper-text"
                                    helperText="password must not be empty"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            password.length > 0 ?
                                                <InputAdornment position="end" className='IconShowHideError'>
                                                    <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                                                        {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null
                                        ),
                                    }}
                                />
                                :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    className='RegisterPasswordTextField'
                                    label="New password"
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            password.length > 0 ?
                                                <InputAdornment position="end" className='InputAdornment'>
                                                    <IconButton onClick={togglePasswordVisibility} className='IconShowHide'>
                                                        {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null
                                        ),
                                    }}
                                />
                    }

                    {Error2 === 'password must be at least 8 characters' ?
                        <TextField
                            error
                            fullWidth
                            variant="outlined"
                            className='RegisterPasswordTextField'
                            label="Confirm new password"
                            id="outlined-error-helper-text"
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={ConfirmPassword}
                            onChange={handleConfirmPasswordChange}
                            helperText="Password must be at least 8 characters"
                            InputProps={{
                                endAdornment: (
                                    ConfirmPassword.length > 0 ?
                                        <InputAdornment position="end" className='IconShowHideError'>
                                            <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHideError'>
                                                {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                            </IconButton>
                                        </InputAdornment>
                                        : null
                                ),
                            }}
                        />

                        :
                        Error2 === 'password must not be empty' ?
                            <TextField
                                error
                                fullWidth

                                variant="outlined"
                                className='RegisterPasswordTextField'
                                label="Confirm new password"
                                id="outlined-error-helper-text"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={ConfirmPassword}
                                helperText="password must not be empty"
                                onChange={handleConfirmPasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        ConfirmPassword.length > 0 ?
                                            <InputAdornment position="end" className='IconShowHideError'>
                                                <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHideError'>
                                                    {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                </IconButton>
                                            </InputAdornment>
                                            : null
                                    ),
                                }}
                            />
                            :
                            Error2 === 'passwords do not match' ?
                                <TextField
                                    error
                                    fullWidth
                                    variant="outlined"
                                    className='RegisterPasswordTextField'
                                    label="Confirm new password"
                                    id="outlined-error-helper-text"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={ConfirmPassword}
                                    helperText="passwords do not match"
                                    onChange={handleConfirmPasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            ConfirmPassword.length > 0 ?
                                                <InputAdornment position="end" className='IconShowHideError'>
                                                    <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHideError'>
                                                        {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null
                                        ),
                                    }}
                                />
                                :
                                Error2 === "Invalid value" ?
                                    <TextField
                                        error
                                        fullWidth
                                        variant="outlined"
                                        className='RegisterPasswordTextField'
                                        label="Confirm new password"
                                        id="outlined-error-helper-text"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={ConfirmPassword}
                                        helperText="Invalid value"
                                        onChange={handleConfirmPasswordChange}
                                        InputProps={{
                                            endAdornment: (
                                                ConfirmPassword.length > 0 ?
                                                    <InputAdornment position="end" className='IconShowHideError'>
                                                        <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHideError'>
                                                            {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    : null
                                            ),
                                        }}
                                    />
                                    :
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        className='RegisterPasswordTextField'
                                        label="Confirm new password"
                                        id="Confirmpassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={ConfirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        InputProps={{
                                            endAdornment: (
                                                ConfirmPassword.length > 0 ?
                                                    <InputAdornment position="end" className='IconShowHide'>
                                                        <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHide'>
                                                            {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    : null
                                            ),
                                        }}
                                    />
                    }



                </div>
            </div>
          <TooManyRequestBSh />

            <div className='ForgetPasswordFooter'>
                <div className='ForgetPasswordLinkDiv'>
                    <button className="ForgetPasswordLink" onClick={() => CheckPassword()}>
                        {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Save'
                        }

                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword