import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Cup from './../../Public/Image/Game/PublicGame/Cup.png'
import './SnackBarEndLeague.css'
import { SetSnackBarEndLeagueState } from './../../app/Game'
import { useDispatch, useSelector } from 'react-redux';

export default function SnackBarEndLeague() {
  const [open, setOpen] = React.useState(true);
  const SnackBarEndLeagueState = useSelector((state) => state.Game.SnackBarEndLeagueState)
  const dispatch = useDispatch()
  const handleClick = () => {
    setOpen(true);
    dispatch(SetSnackBarEndLeagueState(false))

  };

  const handleClose = (event, reason) => {
    let curTime = Date.now();

    localStorage.setItem('CloseSnackBarEndLeagueState', curTime)
    if (reason === 'clickaway') {
      return;
    }
    dispatch(SetSnackBarEndLeagueState(false))
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      {/* <button className='SnackBarEndLeagueButtonRes' onClick={handleClose}>
          View result
      </button> */}
      {/* <Button color="secondary" size="small" onClick={handleClose} clas>
    
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >

        {/* <CloseIcon fontSize="small" /> */}
      </IconButton>
    </React.Fragment>
  );
  const vertical = 'bottom'
  const horizontal = 'center'
  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar
        open={SnackBarEndLeagueState}
        // open={true}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        // message={<img src={Cup} /> + "Competition is over"}
        message={<span id="client-snackbar"><img src={Cup} className='ImgInSnackBarEndLeague' /> New competition has started and the previous one has ended</span>}
        action={action}
      />
    </div>
  );
}