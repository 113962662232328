import React from 'react'
import { GameDB } from '../DataBase/GameDB'
import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState, SetTopJoinerTime, SetIsBetweenLeaguge
} from './../app/Game'
export class GetUserDataFromAppSettingGame extends React.Component {
    constructor() {
        super();
    }
   async GetUserDataFromAppSetting(LevelUser,dispatch) {
    let AppSetting = await GameDB.AppSetting.toArray()
    // console.log(AppSetting) 
    // console.log(LevelUser)
    if (AppSetting.length >= 1) {
        // console.log(1)
        // console.log(AppSetting[0].AppSetting)
        dispatch(SetAppSettingData(AppSetting[0].AppSetting))
        let RewardArray = AppSetting[0].AppSetting.leagues

        if (LevelUser != false) {
            RewardArray.map(item => {

                if (item.leagueLevel == LevelUser) {

                    dispatch(SetRewardLeague(item))
                }
            })
            dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

            let LeaguesArray = AppSetting[0].AppSetting.leagues
            // console.log(LeaguesArray)
            LeaguesArray.map(item => {
                if (item.leagueLevel == LevelUser) {
                    dispatch(SetMyLeagueRank(item.leagueName))
                    // console.log(item.max)
                    dispatch(SetLeagueLimitJoinProgress(item.max))
                    dispatch(SetLeagueMinimumJoinProgress(item.min))

                    dispatch(SetMyLeagueRankNumber(item.leagueLevel))

                }
            })
        }
    } else {
        // console.log(2)
        const timer = setInterval(async () => {
            AppSetting = await GameDB.AppSetting.toArray()
            // console.log(AppSetting)
            if (AppSetting.length == 1) {
                clearInterval(timer);
                this.GetUserDataFromAppSetting(LevelUser,dispatch)
            } else {
                // console.log(147852)
            }

        }, 1000);

    }
    }


}
