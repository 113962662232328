import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { SetUpdate } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import UpdateIcon from './../Public/Image/UpdateIcon.svg'
import { SetCookie } from './../Action/SetCookie'

const Update = () => {
    const dispatch = useDispatch()
    const UpdateField = useSelector((state) => state.Home.Update)
    const UpdateVersion = useSelector((state) => state.Home.UpdateVersion)
    function onDismiss() {
        SetCookie('DissMissUpdateVersion',UpdateVersion,10)
        setTimeout(() => {
            dispatch(SetUpdate(false))
        }, 300);

    }

    return (
        <BottomSheet onDismiss={onDismiss} open={UpdateField}>
            <div className='RegisterBeforeBottomSheet'>
                <div className='RegisterBeforeBottomSheetHeader'>
                    <div className='RegisterBeforeBottomSheetHeaderText' style={{display:"flex"}}>
                        <img src={UpdateIcon} /> New update
                    </div>
                </div>
                <div className='RegisterBeforeBottomSheetBody'>
                    Would you like to update to the new version?
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button className='BottomSheetCancelButton' onClick={() => onDismiss()}>Cancel</button>
                    <button className='BottomSheetLoginButton' onClick={() => window.location.reload()}>Update</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default Update