import Dexie from 'dexie';

export const GameDB = new Dexie('GameDB');
GameDB.version(1).stores({
  PreviousLeague:`++id`,
  Profile: `++id`,
  LeagueList:`++id`,
  AppSetting:`++id`,
  TopJoiner:`++id`
});


