import React from 'react'
import { useLocation } from 'react-router-dom';
import { MyOrderApiAllByUsername } from './MyOrderApiAllByUsername'
import { useEffect, useState } from 'react';
import Back from './../Public/Image/BuyCoin/Back.svg'
import DoneIcon from './../Public/Image/Orders/DoneIcon.svg'
import moment from 'moment';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Snackbar from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StopIcon from './../Public/Image/Orders/StopIcon.svg'
import ReportIcon from './../Public/Image/Orders/ReportIcon.svg'
import ProsessingIcon from './../Public/Image/Orders/prosessing.svg'
import { Link } from 'react-router-dom';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { BottomSheet } from 'react-spring-bottom-sheet'
import DoneBottom from './../Public/Image/Orders/DoneBottom.svg'
import StopBottom from './../Public/Image/Orders/StopBottom.svg'
import ReportBottom from './../Public/Image/Orders/ReportBottom.svg'
import DoneIcon1 from '@mui/icons-material/Done';
import NoOrder from './../Public/Image/Orders/NoOrder.svg'
import { TelegramDB } from './../DataBase/TelegramDB'
import Loading from './../Loading'
import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';
import BackTottop from './../Public/Image/back.svg'
import { useRef } from 'react';
import MemberBlue from './../Public/Image/Orders/MemberBlue.svg'
import visibility from './../Public/Image/Orders/visibility.svg'
const OrderDetailsAll = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const UserName = searchParams.get('username');
    const ID = searchParams.get('id');
    const history = useHistory();
    // const ScrollToTop = () => {
    //     const body = document.querySelector('#OrderDetailsSingleContent');
    //     body.scrollTo({ top: '1000px', behavior: "smooth" })
    // }

    useEffect(() => {
        // TypeFilterOrderSingle
        // StatusFilterOrderSingle
        localStorage.removeItem('TypeFilterOrderSingle')
        localStorage.removeItem('StatusFilterOrderSingle')
        // ScrollToTop()
        // let StatusFilterFromAddress = localStorage.getItem("StatusFilterOrderSingle")
        // let TypeFilterFromAddress = localStorage.getItem("TypeFilterOrderSingle")
        // console.log(StatusFilterFromAddress, TypeFilterFromAddress)
        // if (StatusFilterFromAddress != null) {
        //     console.log(StatusFilterFromAddress)
        //     setStatusFilter(StatusFilterFromAddress)

        // }
        // if (TypeFilterFromAddress != null) {
        //     console.log(TypeFilterFromAddress)
        //     setTypeFilter(TypeFilterFromAddress)
        // }

        GetOrderById()
    }, [])
    const [Data, setData] = useState([])
    const [Page, setPage] = useState(1)
    const [LoadingState, setLoadingState] = useState(false)
    const [EndList, setEndList] = useState(false)
    // const GetOrderById = async () => {
    //     let res = await MyOrderApiAllByUsername('', '', '', UserName)
    //     console.log(res)
    //     if (res.code == 200) {
    //         setData(res.data.data)
    //     }
    // }




    // 
    const [StatusBottomSheet, setStatusBottomSheet] = useState(false)
    const [StatusFilter, setStatusFilter] = useState(false)
    function onDismiss() {
        setTimeout(() => {
            setStatusBottomSheet(false)
        }, 300);
    }

    const SetStatusFilterState = (value) => {
        console.log(value)
        setStatusFilter(value)
        setStatusBottomSheet(false)
        setData([])
        localStorage.setItem("StatusFilterOrderSingle", value)
        GetOrderById(1)



    }

    const [TypeBottomSheet, setTypeBottomSheet] = useState(false)
    const [TypeFilter, setTypeFilter] = useState(false)

    function onDismiss1() {
        setTimeout(() => {
            setTypeBottomSheet(false)
        }, 300);
    }

    const SetTypeFilterState = (value) => {
        setTypeFilter(value)
        setTypeBottomSheet(false)
        setData([])
        localStorage.setItem("TypeFilterOrderSingle", value)
        GetOrderById(1)
    }
    const GetOrderById = async (page) => {
        let TelegramAccount = await TelegramDB.Data.toArray()
        // setShowMore(false)
        // console.log(TelegramAccount)
        let phonenumbers = []
        if (TelegramAccount) {
            if (TelegramAccount.length > 0) {
                TelegramAccount.map(item => {
                    phonenumbers.push(item.PhoneNumber)
                })
            }
        }
        // console.log(page)
        if (page == 1 || page == undefined) {
            setLoadingState(true)

        }
        let username = "";
        let StatusFilterFromAddress = localStorage.getItem("StatusFilterOrderSingle")
        let TypeFilterFromAddress = localStorage.getItem("TypeFilterOrderSingle")
        let UserNameFilterOrder = localStorage.getItem("UserNameFilterOrder")
        if (UserNameFilterOrder != null || UserNameFilterOrder != 'null') {
            if (UserNameFilterOrder) {
                username = UserNameFilterOrder
            }
        }



        // console.log(username)
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.MyOrderApiAllByUsername(page, TypeFilterFromAddress, StatusFilterFromAddress, UserName, phonenumbers)
        // let res = await MyOrderApiAllByUsername(page, TypeFilterFromAddress, StatusFilterFromAddress, UserName, phonenumbers)
        // console.log(res)
        if (res.code == 200) {
            if (res.data.data.length == 0) {
                setEndList(true)
                // setData('')

            } else {
                setEndList(false)

                if (Data.length > 0) {
                    //  setData(prevState => prevState +res.data.data);
                    res.data.data.map(item => {
                        setData((prevState) => [...prevState, item])
                    })


                } else {
                    setData(res.data.data)
                }
            }



        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        } else {
            // console.log('error')
        }
        setLoadingState(false)

    }

    
    const handleScroll = (e) => {
        if (e.target.scrollTop > 50) {
            setScrollToTop(true)
        } else {
            setScrollToTop(false)

        }
        const bottom = (e.target.scrollHeight - e.target.scrollTop) - 100 <= e.target.clientHeight;
        if (bottom) {
            // console.log(bottom)
            // console.log(Page)
            setPage(prevState => prevState + 1);

            GetOrderById(Page + 1)


        } else {
            // console.log(bottom)
        }
    }

    const copyText = async (value) => {
        // Get the input element
        // console.log(value)
        try {
            // Copy text to clipboard
            await navigator.clipboard.writeText(value);
            // console.log('Text copied to clipboard:', value);
            handleClick({ vertical: 'bottom', horizontal: 'center' })

        } catch (err) {
            console.error('Unable to copy text to clipboard', err);
        }
    }
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };




    const [ProcessingBottomSheet, setProcessingBottomSheet] = useState(false)
    function onDismissProcessin() {
        setTimeout(() => {
            setProcessingBottomSheet(false)
        }, 300);
    }
    const OpenBottomSheetInProccess = () => {
        setProcessingBottomSheet(true)
    }
    const ReasonStop = [
        {
            code: 1,
            discription_en: 'Channel or group not be found!\n\nReasons:\n- Unauthorized content including drug sales, sexual, bet and etc.\n- Has a scam badge from Telegram\n- Failure to comply with copyright laws',
            discription_fa: 'کانال یا گروه پیدا نمیشود! \n\nدلایل: \n- محتوای غیر مجاز شامل فروش مواد مخدر، جنسی، شرطبندی و ...\n- دارای نشان کلاهبرداری از تلگرام\n- عدم رعایت قوانین کپی\u200cرایت'
        },
        {
            code: 2,
            discription_en: 'There is no such channel or group in with this address in Telegram.\nIt may have been deleted or their address changed during getting member process.',
            discription_fa: 'چنین کانال یا گروهی در تلگرام با این آدرس وجود ندارد.\n ممکن است کانال حذف شده باشد یا در حین ممبرگیری، آدرس آن تغییر یافته است.'
        },
        {
            code: 3,
            discription_en: 'The channel was stopped by Membersgram.\n\nreasons:\n- Content contrary to the laws\n- Betting and gambling',
            discription_fa: 'کانال توسط ناظر حقوقی ممبرزگرام متوقف شد.\n\nدلایل:\n- محتوای خلاف قوانین جمهوری اسلامی ایران\n- سیاسی و تفرقه افکن\n- شرط\u200cبندی و قمار\nو...'
        },
        {
            code: 4,
            discription_en: 'The channel or group is of the type of accepting the request by admin, and it is currently not possible to receive members for it.',
            discription_fa: 'کانال یا گروه، از نوع قبول درخواست توسط ادمین است و امکان دریافت ممبر برای آن در حال حاضر وجود ندارد.'
        }
    ];

    const [StopBottomSheet, setStopBottomSheet] = useState(false)
    const [ReasonDes, setReasonDes] = useState('')

    function onDismiss12() {
        setTimeout(() => {
            setStopBottomSheet(false)
        }, 300);
    }
    const OpenStopBottomSheet = () => {
        setStopBottomSheet(true)
    }
    const CalculateReason = (reasonStopNumber) => {
        // console.log(Data)

        ReasonStop.map(item => {
            if (item.code == reasonStopNumber) {
                setReasonDes(item.discription_en)

                OpenStopBottomSheet()
            }
        }

        )
    }




    const [scrollToTop, setScrollToTop] = useState(false);
    const contentRef = useRef(null); // Ref to access content element

    const handleScrollToTop = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll specific element
            // setScrollToTop(false)
        }
    };
    const handleScroll1 = (e) => {

        if (e.target.scrollTop > 50) {
            setScrollToTop(true)
        } else {
            setScrollToTop(false)

        }

    }
  console.log(Data)

    return (
        <div className='OrderDetailsSingle' >
            <div className='OrderDetailsSingleHeader'>
                <div className='OrderDetailsSingleHeaderInner'>
                    <Link to={`/OrderDetailsSingle?id=${ID}`}>
                        <img src={Back} className='BackImageIndetails' style={{ "marginRight": "16px", "marginLeft": "8px" }} />
                    </Link>
                    {
                        UserName.length > 14 ? UserName.slice(0, 15) + '...' : UserName
                    }
                </div>

            </div>
            <div className='OrderDetailsSingleContent' ref={contentRef} onScroll={(e) => LoadingState ? null : EndList ? handleScroll1(e) : handleScroll(e)} id="OrderDetailsSingleContent">
                <div className='OrdersFilterSingle'>
                    <div className={TypeFilter == "Type" || TypeFilter == '' ? 'OrdersFilterType' : 'OrdersFilterTypeActive'}
                        onClick={() => setTypeBottomSheet(true)}>
                        {TypeFilter == "Type" || TypeFilter == '' ? null : <DoneIcon1 className='DoneIconMui' />}
                        {TypeFilter == '' ? 'Type' : TypeFilter}  <ArrowDropDownRoundedIcon />
                    </div>

                    <div className={StatusFilter == "Statuses" || StatusFilter == "" ? 'OrdersFilterStatus' : 'OrdersFilterStatusActive'}
                        onClick={() => setStatusBottomSheet(true)}>
                        {StatusFilter == "Statuses" || StatusFilter == '' ? null : <DoneIcon1 className='DoneIconMui' />}
                        {StatusFilter == '' ? "Statuses" : StatusFilter=="Report"?"Reported":StatusFilter} <ArrowDropDownRoundedIcon />
                    </div>
                </div>
                {
                    LoadingState ?
                        <Loading />
                        :
                        Data.length > 0 ?
                            Data.map((item, i) =>
                                <div className='ContentSingleItem1' key={i}>
                                    <div className='ContentSingleItemRow'>
                                        <div className='ContentSingleItemRowKey'>Status</div>


                                        {
                                            item.status == "done" ?
                                                <div className='DoneSingle'>
                                                    {/* {item.status} */}
                                                    Done
                                                    <img src={DoneIcon} className='DoneSingleImg' />

                                                </div>
                                                :
                                                item.status == "stop" ?
                                                    <div className='StopSingle'>
                                                        {/* {item.status} */}
                                                        Stop
                                                        <img src={StopIcon} className='StopSingleImg' />
                                                    </div>
                                                    :
                                                    item.status == "report" ?
                                                        <div className='ReportSingle'>
                                                            {/* {item.status} */}
                                                            Reported
                                                            <img src={ReportIcon} className='ReportSingleImg' />

                                                        </div>
                                                        :
                                                        item.status == "inprocess" ?
                                                            <div className='InProgressSingle' onClick={() => OpenBottomSheetInProccess()}>
                                                                {/* {item.status} */}
                                                                In process

                                                                <img src={ProsessingIcon} className='InProgressSingleImg' />

                                                            </div>
                                                            : null
                                        }





                                    </div>
                                    {

                                        Data ? item.status == "stop" ?
                                            <div className='ContentSingleItemRow'>
                                                <div className='ContentSingleItemRowKey'>Reason for stopping</div>
                                                <div className='ContentSingleItemRowValue' id="BlueViewText" onClick={() => CalculateReason(item.reasonStopNumber)}>
                                                    View
                                                </div>
                                            </div> : null : null
                                    }
                                    <div className='ContentSingleItemRow'>
                                        <div className='ContentSingleItemRowKey'>Requested</div>
                                        <div className='ContentSingleItemRowValue'>
                                            {Data ? item.count.toLocaleString() : ""}
                                            {Data ? item.orderType == "member" ? <PersonIcon className='OrdersPersonIcon' /> : <VisibilityIcon className='OrdersPersonIcon' /> : null}
                                        </div>
                                    </div>

                                    <div className='ContentSingleItemRow'>
                                        <div className='ContentSingleItemRowKey'>Received</div>
                                        <div className='ContentSingleItemRowValue'>
                                            {Data ? item.received.toLocaleString() : ""}
                                            {Data ? item.orderType == "member" ? <PersonIcon className='OrdersPersonIcon' /> : <VisibilityIcon className='OrdersPersonIcon' /> : null}

                                        </div>
                                    </div>
                                    {
                                        item.orderType == "view" ?
                                            <div className='ContentSingleItemRow'>
                                                <div className='ContentSingleItemRowKey'>Post count</div>
                                                <div className='ContentSingleItemRowValue'>
                                                    {Data ? item.postCount.toLocaleString() : ""}</div>
                                            </div>
                                            : null

                                    }
                                    {
                                        item.postLink ?
                                            <div className='ContentSingleItemRow'>
                                                <div className='ContentSingleItemRowKey'>
                                                    {
                                                        item.postCount == 1 ?
                                                            'Post link'
                                                            :
                                                            'Username'
                                                    }

                                                </div>
                                                <div className='ContentSingleItemRowValue'>
                                                    {Data ? item.postLink.length > 14 ? item.postLink.replace("https://t.me/","").slice(0, 15) + '...' : item.postLink.replace("https://t.me/","") : ""}</div>

                                            </div>
                                            : null

                                    }

                                    {
                                        item.orderType == "member" ?
                                            <div className='ContentSingleItemRow'>
                                                <div className='ContentSingleItemRowKey'>Left ( 7 days )</div>
                                                <div className='ContentSingleItemRowValue'>
                                                    {Data ? item.left : ""}</div>
                                            </div>
                                            : null

                                    }

                                    {
                                        item.countrymode != null ?
                                            <div className='ContentSingleItemRow'>
                                                <div className='ContentSingleItemRowKey'>Country</div>
                                                <div className='ContentSingleItemRowValue'>
                                                    {Data ? item.countrymode.name : ""}
                                                    {Data ? <img src={item.countrymode.flag} style={{ 'width': '16px', 'height': '16px' }} /> : ""}
                                                    {/* {item.countrymode.flag} */}
                                                </div>
                                            </div>
                                            : null

                                    }
                                    <div className='ContentSingleItemRow'>
                                        <div className='ContentSingleItemRowKey'>Start time</div>
                                        <div className='ContentSingleItemRowValue'>
                                            {
                                                // item.date
                                                moment(item.date).calendar(null, {
                                                    sameDay: '[Today] , HH : MM',
                                                    nextDay: '[Tomorrow]',
                                                    nextWeek: 'dddd',
                                                    lastDay: '[Yesterday] , HH : MM',
                                                    // lastWeek: '[Last] dddd',
                                                    lastWeek: 'DD MMM , YYYY',
                                                    sameElse: 'DD MMM , YYYY'
                                                })
                                            }</div>
                                    </div>
                                    {item.finishDateTime ?

                                        <div className='ContentSingleItemRow'>
                                            <div className='ContentSingleItemRowKey'>End time</div>
                                            <div className='ContentSingleItemRowValue'>

                                                {/* {item.finishDateTime} */}

                                                {
                                                    moment(item.finishDateTime).calendar(null, {
                                                        sameDay: '[Today] , HH : MM',
                                                        nextDay: '[Tomorrow]',
                                                        nextWeek: 'dddd',
                                                        lastDay: '[Yesterday] , HH : MM',
                                                        // lastWeek: '[Last] dddd',
                                                        lastWeek: 'DD MMM , YYYY',

                                                        sameElse: 'DD MMM , YYYY'
                                                    })
                                                }

                                            </div>
                                        </div>

                                        : null}


                                    <div className='ContentSingleItemRow'>
                                        <div className='ContentSingleItemRowKey'>Order tracking</div>
                                        <div className='ContentSingleItemRowValue BlueOrderTracking' onClick={(e) => copyText(ID)}>

                                            {
                                                ID.length > 8 ?
                                                    ID.substring(0, 8) + '...' :
                                                    ID
                                            }
                                            <ContentCopyIcon className='CopyIDOrder' />
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            <div className='NotResultOrder'>
                                <img src={NoOrder} />

                            </div>

                }

                {scrollToTop ?

                    <button className='top' onClick={handleScrollToTop}>
                        <img src={BackTottop} />
                    </button>
                    :
                    <button className='topHidden' onClick={handleScrollToTop}>
                        <img src={BackTottop} />
                    </button>
                }

                <BottomSheet onDismiss={onDismiss12} open={StopBottomSheet}>
                    <div className='OrdersStatusBottomSheet'>
                        <div className='OrdersStatusBottomSheetHeader'>
                            Reason for stopping
                        </div>
                        <div className='ProcessingBottomSheet'>
                            {
                                ReasonDes ?
                                    <div className='ItemInBottomSheetStop'>
                                        {ReasonDes}
                                    </div>
                                    :
                                    <ul>
                                        {
                                            ReasonStop.map((item, i) =>
                                                <li className='ItemInBottomSheetStop' key={i}>
                                                    {item.discription_en}
                                                </li>
                                            )
                                        }

                                    </ul>
                            }





                            <button className='ProcessingBottomSheetButton' onClick={() => onDismiss12()}>OK</button>
                        </div>
                    </div>
                </BottomSheet >

                <BottomSheet onDismiss={onDismissProcessin} open={ProcessingBottomSheet}>
                    <div className='OrdersStatusBottomSheet'>
                        <div className='OrdersStatusBottomSheetHeader'>
                            Order is processing ...
                        </div>
                        <div className='ProcessingBottomSheet'>
                            <div>
                                Until the status of orders is "completed",
                            </div>
                            <div>
                                Avoid doing these things. Otherwise your order will be canceled and the fee will not be refunded:
                            </div>

                            <ul>
                                <li>
                                    Change channel type or group from public to private mode
                                </li>
                                <li>
                                    Change the address (ID) of the channel or group
                                </li>
                                <li>
                                    Turn on approve new members in group
                                </li>
                            </ul>

                            <button className='ProcessingBottomSheetButton' onClick={() => setProcessingBottomSheet(false)}>OK</button>

                        </div>
                    </div>
                </BottomSheet >


                <BottomSheet onDismiss={onDismiss} open={StatusBottomSheet}>
                    <div className='OrdersStatusBottomSheet'>
                        <div className='OrdersStatusBottomSheetHeader'>
                            Status
                        </div>
                        <div className='OrdersStatusBottomSheetContent'>

                            <div className={StatusFilter == "Statuses" || StatusFilter == false ? 'ActiveStatusFilterTitle' : "UnActiveStatusFilterTitle"} onClick={() => SetStatusFilterState("Statuses")}>
                                <div className='MarginLeft24PX'>All status</div>
                            </div>

                            <div className={StatusFilter == "Prosessing" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetStatusFilterState("Prosessing")}>
                                <img src={ProsessingIcon} className='IconFilterStatus' />
                                In process
                            </div>

                            <div className={StatusFilter == "Done" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetStatusFilterState("Done")}>
                                <img src={DoneBottom} className='IconFilterStatus' />
                                Done
                            </div>

                            <div className={StatusFilter == "Stop" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetStatusFilterState("Stop")}>
                                <img src={StopBottom} className='IconFilterStatus' />
                                Stop
                            </div>

                            <div className={StatusFilter == "Report" ? "ActiveStatusFilterItem BorderRadiusbottom" : 'UnActiveStatusFilterItem BorderRadiusbottom'} onClick={() => SetStatusFilterState("Report")}>
                                <img src={ReportBottom} className='IconFilterStatus' />
                                Reported
                            </div>
                        </div>
                    </div>
                </BottomSheet >

                <BottomSheet onDismiss={onDismiss1} open={TypeBottomSheet}>
                    <div className='OrdersStatusBottomSheet'>
                        <div className='OrdersStatusBottomSheetHeader'>
                            Type
                        </div>
                        <div className='OrdersStatusBottomSheetContent'>

                            <div className={TypeFilter == "Type" || TypeFilter == false ? 'ActiveStatusFilterTitle' : "UnActiveStatusFilterTitle"} onClick={() => SetTypeFilterState("Type")}>
                                <div className='MarginLeft24PX'>All type</div>
                            </div>

                            <div className={TypeFilter == "Member" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetTypeFilterState("Member")}>
                                <img src={MemberBlue} className='IconFilterStatus' />
                                Member
                            </div>

                            <div className={TypeFilter == "View" ? "ActiveStatusFilterItem BorderRadiusbottom" : 'UnActiveStatusFilterItem BorderRadiusbottom'} onClick={() => SetTypeFilterState("View")}>
                                <img src={visibility} className='IconFilterStatus' />
                                View
                            </div>


                        </div>
                    </div>
                </BottomSheet >

            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                // message={` Password changed`}
                key={vertical + horizontal}
                autoHideDuration={1000}
                message={<span id="client-snackbar">
                    {/* <CheckCircleIcon className='CheckIcon'/> */}
                    Order tracking has been copied

                </span>}

            />
        </div>
    )
}

export default OrderDetailsAll