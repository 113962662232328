import React from 'react'
import { useState } from 'react';
import './GetPhoneNumber.css'
import BackImg from './../Public/Image/Login/Back.svg'
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
// import { SendVerificationCodeApi } from './../Api/SendVerificationCodeApi'
import { useHistory } from 'react-router-dom';
import { GetCookie } from './../Action/GetCookie'
import { SetCookie } from './../Action/SetCookie'
import data from './country.json';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useEffect } from 'react';
import axios from "axios";
import { phone } from 'phone';
import { useSelector, useDispatch } from 'react-redux'
import { setMiniLoading } from './../app/Home'
import { db } from './../DataBase/db'
import data1 from './../Auth/country2.json';
import { AllApi } from './../Api/AllApi'

const GetPhoneNumber = () => {


    const history = useHistory();
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)

    const [CountryCode, setCountryCode] = useState('')
    const GetIp = async () => {
        // const res = await axios.get("https://geolocation-db.com/json/");
        try {
            const res = await axios.get("http://ip-api.com/json");
        data.countries.map(item => {
            // if (item.name === res.data.country_name) {
                if (res.data.country.toLocaleLowerCase().indexOf(item.name.toLocaleLowerCase()) >=0) {
                let code = item.code.replace('+', '')

                setCountryCode(code)
            }
        })   
        } catch (error) {
            
        }
     
    }
    useEffect(() => {
        GetIp()
    }, [])

    const CountryCodeHandler = (e) => {
        setCountryCode(e.target.value)
        setCountryError(false)
    }

    const [PhoneNumber, setPhoneNumber] = useState('')
    const PhoneNumberHandler = (e) => {
        setPhoneNumber(e.target.value)
        setInvalidValue(false)
    }
    const [ErrorwaitForResendCode, setErrorwaitForResendCode] = useState(false)
    const [waitForResendCode, setwaitForResendCode] = useState(0)
    const [InvalidValue, setInvalidValue] = useState(false)
    const [CountryError, setCountryError] = useState(false)

    const dispatch = useDispatch()
    async function addUserDb() {
        let CountryName
        data1.map(item=>{
            // let itemName= item.Iso.replace('+', '');
            if(item.Iso.replace('+', '') == CountryCode){
                CountryName=item.countryCode 
            }
            
        })
        try {
            // const id = await db.Data.add({
            //     PhoneNumber
            // });
            let resdata = await db.Data.toArray()
            let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ CountryCode: CountryName })


        } catch (error) {
        }
    }
    const SendVerificationCode = async () => {
        let Token = GetCookie('Token')
        dispatch(setMiniLoading(true))
       
        let FullPhoneNumber = CountryCode + PhoneNumber
        let p = phone('+' + FullPhoneNumber);
        let ClientUser = GetCookie('ClientUser');

        if (p.isValid) {
            addUserDb(CountryCode)
            const MakeApiClass = new AllApi();
            let responseverify=await MakeApiClass.SendVerificationCodeApi(Token, FullPhoneNumber)
            // let responseverify = await SendVerificationCodeApi(Token, FullPhoneNumber)
                let FinalTime
                const now = new Date();
                let currentTimeInMilliseconds = now.getTime();
                if (responseverify.data) {
                    if (responseverify.data.waitForResendCode) {
                        const Limitmilliseconds = responseverify.data.waitForResendCode * 1000;
                        FinalTime = currentTimeInMilliseconds + Limitmilliseconds
                    }
                }

                if (responseverify.code === 201) {
                    // code send
                    // code  201 :  iranian phone
                    history.push(`/CheckVerificationCode?PhoneNumber=${FullPhoneNumber}&&Time=${FinalTime}`);

                } else if (responseverify.code === 300) {
                    // code  300 : Invalid value
                    setInvalidValue(true)
                } else if (responseverify.code === 200) {
                    // code  200 : not iranian phone
                    SetCookie('active', 'true', 100)
                    history.push(`/Home`)
                    // SetCookie('UserActive',true,100)
                } else if (responseverify.code === 301) {
                    // waitForResendCode
                    history.push(`/CheckVerificationCode?PhoneNumber=${FullPhoneNumber}&&Time=${FinalTime}`);

                    setErrorwaitForResendCode(true)
                    setwaitForResendCode(responseverify.data.waitForResendCode)
                }
                //  else if (responseverify.code === 121) {
                //     // code  300 : Invalid value
                //     setInvalidValue(true)
                // }
                else if (responseverify.code == 120 || responseverify.code == 121 || responseverify.code == 122 || responseverify.code == 123) {
                    let name = "Token"
                    let name1 = "active"
                    document.cookie = name + '=; Max-Age=-99999999;';
                    document.cookie = name1 + '=; Max-Age=-99999999;';
                   
                        history.push("/");  
                   
                  
                    // dispatch(setLoading(false))
        
                }
        } else {
            setInvalidValue(true)
            setCountryError(true)
        }
        dispatch(setMiniLoading(false))

    }



    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState('');

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleInputChange = e => {
        setSearchQuery(e.target.value);
        search(e.target.value);
    };
    const search = query => {
        let filteredResults = data.countries.filter(item =>

            item.name.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(filteredResults);
    };
    const selectCountry = (code) => {
        code = code.replace('+', '')

        setCountryCode(code)
        handleClose()
    }
    return (
        <div className='GetPhoneNumberContainer'>
            <div className='GetPhoneNumberHeader'>
                <Link to="/" className='GetPhoneNumberBackIcon'>
                    <img src={BackImg} className='GetPhoneNumberBackIconImg' />
                </Link>
                <div className='GetPhoneNumberHeaderText'>
                    Phone number
                </div>
            </div>
            <div className='GetPhoneNumberBody'>


                <div className='GetPhoneNumberBodyText'>
                    Please enter your Phone number
                </div>
                <div className='GetPhoneNumberInput'>
                    {
                        CountryError ?
                            <TextField
                                error
                                id="outlined-error"
                                variant="outlined"
                                className='GetPhoneNumberCountryTextField' type='text' value={CountryCode}
                                onClick={handleClickOpen}
                                onChange={(e) => CountryCodeHandler(e)}
                                helperText={` `}
                            />
                            : <TextField value={CountryCode}
                                onClick={handleClickOpen}
                                onChange={(e) => CountryCodeHandler(e)} id="outlined-basic" variant="outlined" className='GetPhoneNumberCountryTextField' type='text' />
                    }



                    {ErrorwaitForResendCode ?
                        <TextField
                            error
                            id="outlined-error-helper-text"
                            helperText={`Error waitForResendCode ${waitForResendCode}`}
                            value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='GetPhoneNumberPhoneTextField' type='number' />

                        :
                        InvalidValue ?
                            <TextField
                                error
                                id="outlined-error-helper-text"
                                helperText={`The phone number is incorrect`}
                                value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Invalid value" className='GetPhoneNumberPhoneTextField' type='number' />
                            :
                            <TextField value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='GetPhoneNumberPhoneTextField' type='number' />
                    }


                </div>



            </div>
            <div className='GetPhoneNumberFooter'>
                <div className='GetPhoneNumberLinkDiv'>
                    <button className="GetPhoneNumberLink"
                        onClick={() => SendVerificationCode()}
                    >
                       {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Next'
                        }
                    </button>
                </div>
            </div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div className='SearchDiv'>
                        <input type='text' value={searchQuery}
                            className='SearchInput'
                            placeholder='Search country'
                            onChange={(e) => { handleInputChange(e) }} />
                        <CloseIcon onClick={handleClose} className='CloseModalIcon' />
                    </div>

                </DialogTitle>
                <DialogContent dividers>
                    <div className='ListOfCountry'>
                        {
                            searchResults.length > 0 && searchResults != '' ?
                                searchResults.map((item, i) =>
                                    <div className='ListCountryItem' key={i + 1}>
                                        <div key={i} onClick={() => selectCountry(item.code)} className='ListCountryItemLast'>
                                            {item.name}({item.code})
                                        </div>


                                    </div>
                                )
                                :
                                data.countries.map((item, i) =>
                                    <div className='ListCountryItem' key={i}>
                                        <div key={i} onClick={() => selectCountry(item.code)} className='ListCountryItemLast'>
                                            {item.name}({item.code})
                                        </div>


                                    </div>
                                )
                        }
                    </div>



                </DialogContent>

            </Dialog>
        </div>
    )
}

export default GetPhoneNumber