import React from 'react';
import './LoginGoogle.css'
import Google from './../Public/Image/google.svg'
// import { GoogleLogin } from 'react-google-login';

import { useEffect } from 'react';
import { db } from './../DataBase/db'
import { useState } from 'react';
import { SetCookie } from './../Action/SetCookie'
import { setLoading } from './../app/Home'

import Dexie from 'dexie';
import { AllApi } from './../Api/AllApi'
// import { LoginWithGoogle } from './../Api/LoginWithGoogle'
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { SetEmail } from './../app/Slice'
import { SetPicture } from './../app/Slice'
import { useSelector, useDispatch } from 'react-redux'
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { setTooManyLogin } from './../app/Home'
import TooManyRequestBSh from './../BottomSheet/TooManyRequestBSh'
import { useLocation } from 'react-router-dom';
import { GetCookie } from '../Action/GetCookie'

const LoginGoogle = () => {
  const history = useHistory();

  const [IP, setIP] = useState('')

  const dispatch = useDispatch()

  const Email = useSelector((state) => state.counter.email)
  const Picture = useSelector((state) => state.counter.Picture)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  //  const Code = searchParams.get('code');
  // SetCookie('third-party', 'allowed', 100);


  // window.Telegram.WebView.initParams
  // window.Telegram.WebApp.MainButton.show();
  // window.Telegram.WebApp.onEvent('mainButtonClicked', callback)
  // window.Telegram.webView.getSettings().setUserAgentString("new-membersgram");

  function callback(object) {  
    login()
  }
  useEffect(() => {
 
    if (searchParams.get('code') != null) {
      if (localStorage.getItem('GoogleCode') == undefined) {

        CheckCode(searchParams.get('code'))
        searchParams.delete('code');
        searchParams.delete('scope');
        searchParams.delete('authuser');
        const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({ path: newUrl }, '', newUrl);
      }

    }

  }, [])
  const CheckCode = (Code) => {

    if (Code != null) {

      SendTokenToServer(Code)

    }

  }
  useEffect(() => {

    GetIp()
    if (Email.length > 0) {

      addUserDb(Email, Picture)
    }
  }, [Email])

  const GetIp = async () => {
    // const IpRes = await axios.get("https://geolocation-db.com/json/");
    try {
       const IpRes = await axios.get("http://ip-api.com/json");

    // setIP(IpRes.data.country_name)
    setIP(IpRes.data.country)
    } catch (error) {
    setIP(false)
      
    }
   
  }
  const Numbersgram = GetCookie('Numbersgram');

  const SendTokenToServer = async (token) => {
    dispatch(setLoading(true))

    localStorage.setItem('GoogleCode', true)
    // searchParams.delete('code');

    // Code = null
    var ret = token.replace('Bearer ', '');

    let NeedPhone = false
    if (IP === 'Iran') {
      NeedPhone = true
    }
    // let res = await LoginWithGoogle(ret, NeedPhone)

    const MakeApiClass = new AllApi();
    let ClientUser = GetCookie('ClientUser');

    // let res=await MakeApiClass.LoginWithGoogle(ret, NeedPhone)
    let res = await MakeApiClass.LoginWithGoogle(token, NeedPhone,ClientUser)
    // console.log(res)
    if (res.code == 429) {
      dispatch(setTooManyLogin(true))
    }
    else
      if (res.code == 200) {

        // data.data.email
        dispatch(SetEmail(res.data.data.email))
        dispatch(SetPicture(res.data.data.picture))
        localStorage.setItem('SessionFromApi', JSON.stringify(res.data.data.sessions))


        if (res.data.data.active === true) {
          SetCookie('Token', res.data.data.token, 100);
          SetCookie('active', 'true', 100);
          if(Numbersgram=='Numbersgram'){
            history.push(`/Coin?Status=Sale`);

            }else{
              history.push(`/Home`);  
            }
          // history.push(`/Home`);
          // addUserDb('Email')

        } else {
          SetCookie('Token', res.data.data.token, 100);
          SetCookie('active', 'false', 100);
          history.push(`/GetPhoneNumber`);
          // addUserDb('Email')
        }


      } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
        let name = "Token"
        let name1 = "active"
        document.cookie = name + '=; Max-Age=-99999999;';
        document.cookie = name1 + '=; Max-Age=-99999999;';
        history.push("/");
        // dispatch(setLoading(false))

      }
    localStorage.removeItem('GoogleCode')

  }

  async function addUserDb(Email, Picture) {
    // let resdata
    Dexie.exists('MembersgramDB').then(async function (exists) {
      if (!exists) {
        // var db = new Dexie('my-database');
        // db.version(1).stores(...);
        // db.open();
        var newdb = new Dexie('MembersgramDB');
        newdb.version(1).stores({
          Data: '++id, Email,PhoneNumber,Picture'
        });
        db.open();
        const id = await newdb.Data.add({
          Email, Picture
        });
        // Status=true
        // let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
        // let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ Picture: Picture })
      } else {
        // const id = await db.Data.add({
        //     Email, Picture
        // });
        let resdata = await db.Data.toArray()
        let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
        let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ Picture: Picture })
        //   return true

      }
    });



    // if (resdata.length == 0) {
    //   const id = await db.Data.add({
    //     Email
    //   });
    // } else {
    //   let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
    //   let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ Picture: Picture })
    //   // let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ CountryCode: '' })
    //   // let res2 = db.Data.where({ id: Number(resdata[0].id) }).modify({ PhoneNumber:''  })

    // }

  }

  // const responseGoogle = (response) => {
  //   try {
  //     // SendTokenToServer(response.tokenId)
  //     SendTokenToServer(response.credential)
  //   } catch (error) {
  //   }

  // }

  const login = useGoogleLogin({

    ux_mode: 'redirect', flow: 'auth-code', hint: 'hello',
    onSuccess: async (response) => {
      try {
        // alert(response)

        dispatch(setLoading(true))
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`
            },
          }
        )

        // SendTokenToServer(res.config.headers.Authorization)
        // SendTokenToServer(res.config.headers.Authorization)


      } catch (error) {
        // alert(error)

      }
    }

    //  dispatch(setLoading(false))
    // flow: 'implicit',
  });

  return (
    <div>







      <button className='GoogleLoginButton' id='GoogleLoginButton' onClick={() => { login() }} >
        <img src={Google} className='GoogleLoginButtonIcon ' />
        Continue with Google

      </button>
      {/* <GoogleLogin
  onSuccess={credentialResponse => {
  }}
  onError={() => {
  }}
 
  useOneTap
/> */}
      <div id='loginbutton' >
        {/* <useGoogleOneTapLogin /> */}

        {/* <GoogleLogin
          clientId="490978921152-eahmgen2ihogmfd6issj4p4b2u88v4dv.apps.googleusercontent.com"
          useOneTap={true}
          auto_select={false}
          width='100%'
          onSuccess={responseGoogle}
          onFailure={responseGoogle} */}
        {/* // ux_mode='popup'
          // theme='filled_blue'
          // allowed_parent_origin='http://localhost:3000/'
          // cookiePolicy={'single_host_origin'}
          // render={renderProps => ( */}
        {/* //   <button className='GoogleLoginButton' onClick={renderProps}>
          //     <img src={Google} className='GoogleLoginButtonIcon ' />
          //     Continue with Google

          //   </button>
          // )}
        // /> */}
        {/* <button onClick={() => login()}>Sign in with Google 🚀</button>; */}
        {/* <MyCustomButton onClick={() => login()}>Sign in with Google 🚀</MyCustomButton>; */}

      </div>



      <TooManyRequestBSh />
    </div>
  )
}

export default LoginGoogle