import React from 'react'
import { useEffect, useState } from 'react'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { API_ID, API_HASH } from './../Const'
import { TelegramDB } from './../DataBase/TelegramDB'
import { AllApi } from './../Api/AllApi'

export async function GetMessageView(Session, BotSession, DataView) {

  const stringSession = new StringSession(Session);
  const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
    connectionRetries: 1,
  });
  const connectPromise = await Client.connect();

  const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
  //     let resawait = await sleep(5000);
 

  async function jsHello(time) {
    let status = false;
    for (let i = 0; i <= time; i++) {


      if (i < time) {
        await sleep(1000);
   
        status = true
      } else {
        status = false
        break
      }

    }
    return status
  }


  const MakeApiClass = new AllApi();
  // DataView.map(async (item) => {


  for (let index = 0; index < DataView.length; index++) {
    let res = await jsHello(30);
    if (res == false) {
  


      let post_Id
      if (DataView[index].messageIds[0] != '') {

        post_Id = DataView[index].post_Id
      }
      if (DataView[index].count == 1) {

        let messageIds = DataView[index].messageIds
        let username = DataView[index].username
  
        try {
          const result1 = await Client.invoke(
            new Api.messages.GetMessagesViews({
              peer: username,
              id: [messageIds],
              increment: true,
            })
          );
       
          let saveViewPostRes = await MakeApiClass.saveViewPost(BotSession, DataView[index].post_Id)
        } catch (error) {
       

        }



      } else {
        let username = DataView[index].username
        let messages = await Client.getMessages(username, { limit: DataView[index].count })




        for (let i = 0; i < messages.length; i++) {
          let messageIds = messages[i].id
          try {
            const result1 = await Client.invoke(
              new Api.messages.GetMessagesViews({
                peer: username,
                id: [messageIds],
                increment: true,
              })
            );
       
            let saveViewPostRes = await MakeApiClass.saveViewPost(BotSession, DataView[index].post_Id)
          } catch (error) {
       
          }


       
        }



      }

    }

  }





  // })




}