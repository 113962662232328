import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BackImg from './../Public/Image/Login/Back.svg'
import TextField from '@mui/material/TextField';
import './Register.css'
import { useSelector, useDispatch } from 'react-redux'
import { SetEmail } from './../app/Slice'
import { useHistory } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import ImageBottomSheet from './../Public/Image/Register/BottomSheetIcon.svg'
// import { CheckEmailApi } from './../Api/CheckEmailApi'
import Snackbar from '@mui/material/Snackbar';
import { GetCookie } from './../Action/GetCookie'
import { setMiniLoading } from './../app/Home'
import { AllApi } from './../Api/AllApi'
import { setTooManyLogin } from './../app/Home'
import TooManyRequestBSh from './../BottomSheet/TooManyRequestBSh'
const Register = () => {
    const history = useHistory();
    const Token = GetCookie('Token')
    let active = GetCookie('active');

    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const Numbersgram = GetCookie('Numbersgram');

    useEffect(() => {
        if (active === 'true') {
            
            if(Numbersgram=='Numbersgram'){
                history.push(`/Coin?Status=Sale`);

                }else{
                  history.push(`/Home`);  
                }
            // history.push(`/Home`);
        }
    }, [])
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };

    const [open, setOpen] = useState(false)
    function onDismiss() {
        setTimeout(() => {
        setOpen(false)
    }, 300);
    }
    const EmailFromRedux = useSelector((state) => state.counter.email)
    const dispatch = useDispatch()
    const [Email, setEmail] = useState('')
    const [Error, setError] = useState(false)
    const [EmailEmpty, setEmailEmpty] = useState(false)
    const HandleEmailChange = (email) => {
        let result = email.trim();
        result=result.toLowerCase();
        setEmailEmpty(false)
        setEmail(result)
        dispatch(SetEmail(result))
        setError(false)
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const CheckEmail = async () => {
        dispatch(setMiniLoading(true))

        if(Email.length == 0){
            setEmailEmpty(true)
        }
        let Validate = validateEmail(Email)
        if (Validate !== null) {
            const MakeApiClass = new AllApi();
            let CheckEmailApiResponse=await MakeApiClass.CheckEmailApi(Email)
            // let CheckEmailApiResponse = await CheckEmailApi(Email)
            if (CheckEmailApiResponse.code == 429) {
                dispatch(setTooManyLogin(true)) 
            }
           else
            if (CheckEmailApiResponse.code === 200) {
                history.push(`/RegisterGetPassword?email=${Email}`);
            } else if (CheckEmailApiResponse.code === 301) {
                setOpen(true)
            }  else if (CheckEmailApiResponse.code == 120 || CheckEmailApiResponse.code == 121 || CheckEmailApiResponse.code == 122 || CheckEmailApiResponse.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))
    
            } 
            else {
                handleClick({ vertical: 'bottom', horizontal: 'center' })
            }
        } else {
            setError(true)
        }
        dispatch(setMiniLoading(false))

    }
    return (
        <div className='RegisterContainer'>
            <div className='RegisterHeader'>
                <Link to="/" className='RegisterBackIcon'>
                    <img src={BackImg} className='RegisterBackIconImg' />
                </Link>
                <div className='RegisterHeaderText'>
                    Register
                </div>
            </div>
            <div className='RegisterBody'>
                {/* <button onClick={()=>handleClick({ vertical: 'bottom', horizontal: 'center' })}>Open simple snackbar</button> */}
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={opens}
                    onClose={handleClose}
                    message="Something went wrong"
                    key={vertical + horizontal}
                    autoHideDuration={3000}
                />
                <BottomSheet onDismiss={onDismiss} open={open}>
                    <div className='RegisterBeforeBottomSheet'>
                        <div className='RegisterBeforeBottomSheetHeader'>
                            <img src={ImageBottomSheet} />
                            <div className='RegisterBeforeBottomSheetHeaderText'>You have registered before</div>
                        </div>
                        <div className='RegisterBeforeBottomSheetBody'>This Email is already connected to an account, Do you want to log in?</div>
                        <div className='RegisterBeforeBottomSheetFooter'>
                            <button className='BottomSheetCancelButton' onClick={() => setOpen(false)}>Cancel</button>
                            <Link to="/Login" className='BottomSheetLoginButton'>Login</Link>
                        </div>
                    </div>
                </BottomSheet>


                <div className='RegisterBodyText'>
                    Please enter your Email
                </div>
                <div className='RegisterEmailInput'>
                    {
                        EmailEmpty?
                        <TextField
                            error
                            id="outlined-error-helper-text"
                            label="Email"
                            value={Email}
                            onChange={(e) => HandleEmailChange(e.target.value)}
                            helperText="email  is empty"
                            className='RegisterEmailTextField'
                        />
                        :
                        Error ? <TextField
                            error
                            id="outlined-error-helper-text"
                            label="Email"
                            value={Email}
                            onChange={(e) => HandleEmailChange(e.target.value)}
                            helperText="email format is incorrect"
                            className='RegisterEmailTextField'
                        />
                            :
                            <TextField id="outlined-basic" label="Email" value={Email}
                                onChange={(e) => HandleEmailChange(e.target.value)}
                                variant="outlined" className='RegisterEmailTextField' />
                    }


                </div>



            </div>
          <TooManyRequestBSh />

            <div className='RegisterFooter'>
                <div className='RegisterLinkDiv'>
                    <button className="RegisterLink" onClick={() => CheckEmail()}>
                        {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Next'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Register