import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import { TelegramClient, Api } from 'telegram'
import { StringSession } from 'telegram/sessions'
import { API_ID, API_HASH } from './../Const'
import { setMiniLoading } from './../app/Home'
import { UpdateStateTelegram, SaveSession, SetPhoneNumber, SetPhoneCodeHash } from './../app/Slice'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackImg from './../Public/Image/Login/Back.svg'
import Bannedicon from './../Public/Image/Bannedicon.svg'

import data from './../Auth/country.json';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import './LoginTelegram.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from "axios";
import { TelegramDB } from './../DataBase/TelegramDB'
import { BottomSheet } from 'react-spring-bottom-sheet'
// import Vector from './../Public/Image/Vector.svg'
import { RequestToTelegramDb } from './../Coin/RequestToTelegramDb'
import { setTelegramAccount } from './../app/JoinState'
import Snackbar from '@mui/material/Snackbar';
import { SetUserTelegramPasswordStatus } from './../app/JoinState'
// import BackImg from './../Public/Image/Login/Back.svg'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SetCookie } from './../Action/SetCookie'

const LoginTelegram = () => {

    const [PhoneNumber, setPhoneNumber] = useState('')
    const history = useHistory();
    const Session = useSelector((state) => state.counter.Session)
    const [ErrorLogin, setErrorLogin] = useState(false)
    const [PHONE_NUMBER_INVALID, setPHONE_NUMBER_INVALID] = useState(false)
    const [PHONE_NUMBER_BANNED, setPHONE_NUMBER_BANNED] = useState(false)
    const [ConnectionError, setConnectionError] = useState(false)
    const TelegramAccount = useSelector((state) => state.JoinState.TelegramAccount)
    const [DuplicateNumber, setDuplicateNumber] = useState(false)
    const [FloodWait, setFloodWait] = useState(false)
    // const [FloodWaitTime, setFloodWaitTime] = useState(0)
    const State = useSelector((state) => state.counter.Authentication)
    const dispatch = useDispatch()

    function onDismiss() {
        setTimeout(() => {

            setFloodWait(false)
        }, 300);


    }
    const stringSession = new StringSession('');
    const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
        connectionRetries: 3, timeout: 3
    });

    const GetRequestToTelegramDb = async () => {
        const res = await RequestToTelegramDb()
        dispatch(setTelegramAccount(res))
    }
    GetRequestToTelegramDb()

    const RemoveLogOutedNumber = async (id) => {
        try {
            const id1 = await TelegramDB.Data.delete(id);
            return true
        } catch (error) {
            // console.log(`Failed to delete ${id}: ${error}`);
        }
    }
    const CheckBeforeSendCode = async () => {
        let Duplacate = []
        if (TelegramAccount) {
            await Promise.all(TelegramAccount.map(async (item) => {
                if (item.PhoneNumber == CountryCode + PhoneNumber) {
                    if (item.BotSession === '') {
                        history.push(`/JoinBot`);
                    }
                    if (item.Session == false) {
                        Duplacate.push(false)
                        let res = await RemoveLogOutedNumber(item.id)
                    } else {
                        setDuplicateNumber(true)
                        Duplacate.push(true)
                        dispatch(setMiniLoading(false))
                    }

                } else {
                    Duplacate.push(false)
                }
            })
            )
            return Duplacate
        }
        return Duplacate

    }

    const SendCodeFunction = async () => {
        if (CountryName == '') {
            setCountryError(true)

        } else {
            dispatch(setMiniLoading(true))
            let ResponseCheck = await CheckBeforeSendCode()
            // console.log(ResponseCheck)
            let index = ResponseCheck.indexOf(true);
            // console.log(index)
            if (index < 0) {
                let res
                try {


                    res = await Client.connect();

                    // console.log(res)
                } catch (error) {
                    // console.log(error)
                    setConnectionError(true)
                    setSnackbaropen(true)
                    dispatch(setMiniLoading(false))
                }

                if (res === false) {
                    setConnectionError(true)
                    dispatch(setMiniLoading(false))
                    setSnackbaropen(true)

                } else if (res == true || res == undefined) {
                    try {
                        // console.log(res)
                        const sendcode = await Client.invoke(
                            new Api.auth.SendCode({
                                phoneNumber: CountryCode + PhoneNumber,

                                apiId: API_ID,
                                apiHash: API_HASH,
                                settings: new Api.CodeSettings({
                                    allowFlashcall: false,
                                }),
                            })
                        );

                        // console.log(sendcode)


                        localStorage.setItem('session', JSON.stringify(Client.session.save()))
                        localStorage.setItem('PhoneNumber', CountryCode + PhoneNumber)

                        localStorage.setItem('CountryCode', CountryCode)
                        dispatch(SaveSession(Client.session.save()))


                        //  

                        dispatch(UpdateStateTelegram('CodeSent'))
                        dispatch(SetPhoneNumber(CountryCode + PhoneNumber))
                        dispatch(SetPhoneCodeHash(sendcode.phoneCodeHash))
                        dispatch(setMiniLoading(false))
                        history.push(`/VerifyCode`);
                    } catch (error) {
                        dispatch(setMiniLoading(false))
                        console.log(error)
                        if (error.errorMessage === 'PHONE_NUMBER_INVALID') {
                            setPHONE_NUMBER_INVALID(true)
                        } else if (error.errorMessage === 'FLOOD' || error.errorMessage == "PHONE_PASSWORD_FLOOD") {
                            // console.log(error.seconds)
                            // let Second = error.seconds
                            setFloodWait(true)
                            // setFloodWaitTime(Second)
                            // var mind = Second % (60 * 60);
                            // var minutes = Math.floor(mind / 60);
                            // console.log(minutes)
                            // AddTelegramSession(error.seconds,CountryCode + PhoneNumber)
                        }else if(error.errorMessage == "PHONE_NUMBER_BANNED"){
                            setPHONE_NUMBER_BANNED(true)

                        }
                        // setErrorLogin(true)
                    }
                }

            }
        }


    }

    const MiniLoading = useSelector((state) => state.Home.MiniLoading)

    const [CountryCode, setCountryCode] = useState('')
    const [CountryName, setCountryName] = useState('')
    const [CountryError, setCountryError] = useState(false)
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const [Snackbaropen, setSnackbaropen] = useState(false);
    const handleCloseSnackbaropen = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbaropen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [InvalidValue, setInvalidValue] = useState(false)
    const GetIp = async () => {
        // const res = await axios.get("https://geolocation-db.com/json/");
        try {
              const res = await axios.get("http://ip-api.com/json");

        // const res = await axios.get("https://ipapi.co/json/");

        //   console.log(res.data.country_name)
        //   console.log(res)
        data.countries.map(item => {
            // if (item.name === res.data.country_name) {
      if (res.data.country.toLocaleLowerCase().indexOf(item.name.toLocaleLowerCase()) >=0) {

                // console.log(item)

                let code = item.code.replace('+', '')
                setCountryName(item.name)
                setCountryCode(code)
            }
        })  
        } catch (error) {
            
        }

    }
    useEffect(() => {
        localStorage.removeItem("AllowSend")
        localStorage.removeItem('PassWord2Step')
        localStorage.removeItem('AllowSendCode')
        localStorage.removeItem('setCodeErrorSetLocalAllow')
        localStorage.removeItem('Code')
        dispatch(SetUserTelegramPasswordStatus(false))

        GetIp()
    }, [])
    const search = query => {
        // console.log(query)
        // console.log(data)

        let filteredResults = data.countries.filter(item =>

            item.name.toLowerCase().includes(query.toLowerCase())
        );
        let filteredResultsCode = data.countries.filter(item =>

            item.code.replace(" ","").includes(query)
        );
        
        setSearchResults(filteredResults.length>0 ?filteredResults:filteredResultsCode );
        // console.log(filteredResults)
    };
    const selectCountry = (code, name) => {
        // console.log(code,name)
        setCountryName(name)
        code = code.replace('+', '')

        setCountryCode(code)
        handleClose()
    }
    const PhoneNumberHandler = (e) => {
        setPhoneNumber(e.target.value)
        setInvalidValue(false)
        setPHONE_NUMBER_INVALID(false)
        setDuplicateNumber(false)
        setFloodWait(false)

    }
    const handleInputChange = e => {
        setSearchQuery(e.target.value);
        search(e.target.value);
    };
    const CountryCodeHandler = (e) => {
        setCountryError(false)
        let value = e.target.value
        value = value.trim()
        // console.log(value)
        // console.log(value.startsWith('+'))
        if (value.startsWith('+')) {
            value = value.replace('+', '')
        }
        // console.log(value)
        for (let index = 0; index < data.countries.length; index++) {
            const item = data.countries[index];
            // console.log(item.code.replace(/\s/g, '').startsWith('+' + value))
            // if (item.code.replace(/\s/g, '').startsWith('+' + value)) {
            if (item.code.replace(/\s/g, '') == '+' + value) {

                // console.log(item)
                setCountryName(item.name)
                setCountryCode(item.code)
                setCountryError(false)
                break;
                // } 
            } else {
                setCountryName('')
            }


        }

        setCountryCode(e.target.value)
    }
    const SelectCheckBox = (e) => {
        // console.log(e.target.checked) 
        SetCookie('SaveAaccount', e.target.checked, 100)
    }
    return (
        <div className='LoginTelegramContainer'>
            <div className='LoginTelegramHeader'>
                <Link to="/Coin?Status=Free" className="RegisterBackIconLoginTelegram" >
                    <img src={BackImg} className="RegisterBackIconImg" />

                </Link>
                {/* <Link to="/Coin" className='LoginTelegramBackIcon'>
                    <img src={BackImg} className='LoginTelegramBackIconImg' />
                </Link> */}
                <div className='LoginTelegramHeaderText'>
                    Login to Telegram
                </div>
            </div>
            <div className='LoginTelegramBody'>

                <div className='LoginTelegramBodyText' onClick={handleClickOpen}>
                    <div className='LoginTelegramBodyTextCountry'>
                        {CountryName ? CountryName : "Country"}
                    </div>
                    <div className=''>
                        <KeyboardArrowRightIcon className='LoginTelegramBodyTextIcon' />
                    </div>
                </div>
                <div className='LoginTelegramInput'>
                    {
                        CountryError ?
                            <TextField
                                error
                                id="outlined-error"
                                variant="outlined"
                                className='LoginTelegramCountryTextField' type='number'
                                value={CountryCode}
                                // onClick={handleClickOpen}
                                onChange={(e) => CountryCodeHandler(e)}
                                helperText={``}
                                min={0}
                                inputProps={{
                                    maxLength: 5,
                                    type: 'number',
                                }}
                            />
                            :
                            <TextField
                                value={CountryCode}

                                // onClick={handleClickOpen}
                                onChange={(e) => CountryCodeHandler(e)}
                                id="outlined-basic" variant="outlined"
                                className='LoginTelegramCountryTextField'
                                type='number'
                                min={0}
                                inputProps={{
                                    maxLength: 5,
                                    type: 'number',
                                }} />
                    }



                    {
                        // FloodWait ?
                        //     <TextField
                        //         error
                        //         id="outlined-error-helper-text"
                        //         helperText={`FloodWait ${FloodWaitTime}`}
                        //         value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)}
                        //         label="Phone number" className='LoginTelegramPhoneTextField' type='number' />
                        //     :
                        DuplicateNumber ?
                            <TextField
                                error
                                id="outlined-error-helper-text"
                                helperText={`This number is already logged in`}
                                value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)}
                                label="Phone number" className='LoginTelegramPhoneTextField' type='number' />
                            :
                            // ConnectionError ?
                            //     <TextField
                            //         error
                            //         id="outlined-error-helper-text"
                            //         helperText={`ConnectionError`}
                            //         value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='LoginTelegramPhoneTextField' type='number' />
                            //     :
                            InvalidValue ?
                                <TextField
                                    error
                                    id="outlined-error-helper-text"
                                    helperText={`Error`}
                                    value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='LoginTelegramPhoneTextField' type='number' />
                                :
                                PHONE_NUMBER_INVALID ?
                                    <TextField
                                        error
                                        id="outlined-error-helper-text"
                                        helperText={`Phone number is incorrect`}
                                        value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='LoginTelegramPhoneTextField' type='number' /> :
                                    CountryError ?
                                        <TextField
                                            error
                                            id="outlined-error-helper-text"
                                            helperText={`Country code  is incorrect`}
                                            value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='LoginTelegramPhoneTextField' type='number' />
                                        :

                                        <TextField value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='LoginTelegramPhoneTextField' type='number' />
                    }


                </div>
                <div className='LoginTelegramWarning'>
                    <strong >⚠️Warning</strong>
                    : Please avoid adding your primary Telegram account and use Virtual number
                </div>


            </div>
            <div className='LoginTelegramFooter'>
                <div className='LoginTelegramLinkDiv'>
                <div className='LoginTelegramLinkDivStart'>
                <FormControlLabel control={<Checkbox defaultChecked={false} style={{ "font-size": "12px" }} onChange={(e) => SelectCheckBox(e)} />} label="Save account to my email" />
                    </div>
                    <button className="LoginTelegramLink"
                        onClick={() => MiniLoading ? null : SendCodeFunction()}
                    // onClick={() => SendVerificationCode()}
                    >
                        {MiniLoading ?
                            <div className="loadminijoin">
                                {/* <img src={Vector} style={{'display':'flex'}}/> */}
                            </div>
                            :
                            <ArrowForwardIcon />

                        }
                    </button>
                 
                </div> 
                
                

            </div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div className='SearchDiv'>
                        <input type='text' value={searchQuery}
                            className='SearchInput'
                            placeholder='Search country'
                            autoFocus
                            onChange={(e) => { handleInputChange(e) }} />
                        <CloseIcon onClick={handleClose} className='CloseModalIcon' />
                    </div>

                </DialogTitle>
                <DialogContent dividers>
                    <div className='ListOfCountry'>
                        {
                            searchResults.length > 0 && searchResults != '' ?
                                searchResults.map((item, i) =>
                                    <div className='ListCountryItem' key={i + 1} onClick={() => selectCountry(item.code, item.name)}>
                                        <div key={i} className='ListCountryItemLast'>
                                            {item.name}({item.code})
                                        </div>


                                    </div>
                                )
                                :
                                data.countries.map((item, i) =>
                                    <div className='ListCountryItem' key={i} onClick={() => selectCountry(item.code, item.name)}>
                                        <div key={i} className='ListCountryItemLast'>
                                            {item.name}({item.code})
                                        </div>


                                    </div>
                                )
                        }
                    </div>



                </DialogContent>

            </Dialog>
            <BottomSheet onDismiss={onDismiss} open={FloodWait}>
                <div className='RegisterBeforeBottomSheet'>
                    <div className='RegisterBeforeBottomSheetHeader'>
                        <div className='RegisterBeforeBottomSheetHeaderText'>Number limitation</div>
                    </div>
                    <div className='RegisterBeforeBottomSheetBody'>
                        <span className='PhoneNumberInBottomSheet'>+{CountryCode + PhoneNumber}</span> is restricted, and you cannot log in with it. Please wait for a while or use another number
                    </div>
                    <div className='RegisterBeforeBottomSheetFooter'>
                        {/* <button className='BottomSheetCancelButton' >Cancel</button>  */}
                        <button style={{ 'width': '100%' }} className='BottomSheetLoginButton' onClick={() => setFloodWait(false)} >Got it</button>
                    </div>
                </div>
            </BottomSheet>

            <BottomSheet onDismiss={onDismiss}   
            open={PHONE_NUMBER_BANNED} 
            // open={true}
              >
                <div className='RegisterBeforeBottomSheet'>
                    <div className='RegisterBeforeBottomSheetHeader'>
                        <div className='RegisterBeforeBottomSheetHeaderText'>Number is banned</div>
                    </div>
                    <div className='RegisterBeforeBottomSheetBodyBanned'>
                        <span className='PhoneNumberInBottomSheet'><img src={Bannedicon} className='BannedImage'/></span> Please enter another phone number
                    </div>
                    <div className='RegisterBeforeBottomSheetFooter'>
                        {/* <button className='BottomSheetCancelButton' >Cancel</button>  */}
                        <button style={{ 'width': '100%' }} className='BottomSheetLoginButton' onClick={() => setPHONE_NUMBER_BANNED(false)} >Got it</button>
                    </div>
                </div>
            </BottomSheet>


            <Snackbar
                open={Snackbaropen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbaropen}
                message="Something went wrong! Please try again."

            />
        </div>
    )
}

export default LoginTelegram