import * as React from 'react';
import './AllReward.css'
import BronzeActive from './../../Public/Image/Game/AllReward/BronzeActive.svg'
import SilverActive from './../../Public/Image/Game/AllReward/SilverActive.svg'
import GoldActive from './../../Public/Image/Game/AllReward/03_gold 1.svg'
import PlatinumActive from './../../Public/Image/Game/AllReward/04_platin 1.svg'
import DiamondActive from './../../Public/Image/Game/AllReward/05_dimond 2.svg'
import EpicActive from './../../Public/Image/Game/AllReward/Epic.svg'
import LegendaryActive from './../../Public/Image/Game/AllReward/07_legen.svg'
import MasterActive from './../../Public/Image/Game/AllReward/08_masterf 1.svg'
import GrandmasterActive from './../../Public/Image/Game/AllReward/09_grandmaster.svg'
import LordActive from './../../Public/Image/Game/AllReward/10_lordf 1.svg'

import CreatorActive from './../../Public/Image/Game/AllReward/11_creator 1.svg'
import Creator1DeActive from './../../Public/Image/Game/AllReward/emoji_events.svg'
import Creator1Active from './../../Public/Image/Game/AllReward/Activeemoji_events.svg'

import Lock from './../../Public/Image/Game/AllReward/Lock.svg'

import Back from './../../Public/Image/Game/AllReward/ic_actionbar_back.svg'
import { Link } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import { AllApi } from '../../Api/AllApi'
import { SetUserLeagueData } from './../../app/Game'
import { SetMyLeagueRank } from './../../app/Game'
import { SetAppSettingData } from './../../app/Game'
import { SetRewardLeague } from './../../app/Game'
import { SetLeagueLimitJoinProgress } from './../../app/Game'
import { SetMyLeagueRankNumber } from './../../app/Game'
import { SetGiftTopjoin } from './../../app/Game'
import { SetLevelUserHeaderProgress } from './../../app/Game'

import CoinDeactive from './../../Public/Image/Game/AllReward/CoinDeactive.svg'
import StarDeactive from './../../Public/Image/Game/AllReward/StarDeactive.png'
import HeaderProgressBar from './../HeaderProgressBar'
import HeadImg from './../../../src/Public/Image/Game/Group 26086606.svg'
import { useSelector, useDispatch } from 'react-redux'
import Loading from './../../Loading'
import { useState } from 'react';
const AllReward = () => {
    const dispatch = useDispatch()
    const [LoadState, setLoadState] = useState(true)

    const AppSettingData2 = useSelector((state) => state.Game.AppSettingData)
    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const MyLeagueRankNumber = useSelector((state) => state.Game.MyLeagueRankNumber)
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)

    let Data = [
        {
            id: 1, name: 'Bronze', img: BronzeActive, titlecolor: '#BF8970', imgbgcolor: '#FFEDE6', titleTextColor: '#321203', bodyBG: '#FFEDE6',
            Active: [
                { status: false, name: false, xp: false, coin: false, min: false, max: false },
                { status: false, name: false, xp: false, coin: false, min: false, max: false },
                { status: false, name: false, xp: false, coin: false, min: false, max: false }],
            DeActive: false,
        },
        {
            id: 2, name: 'Silver', img: SilverActive, titlecolor: '#8E9FAF', titleTextColor: '#22323F', imgbgcolor: '#D3E5F6', bodyBG: '#D3E5F6',
            Active: [
                { status: false, name: false, xp: false, coin: false, min: false, max: false },
                { status: false, name: false, xp: false, coin: false, min: false, max: false },
                { status: false, name: false, xp: false, coin: false, min: false, max: false }]
            , DeActive: false,

        },
        {
            id: 3, name: 'Gold', img: GoldActive, titlecolor: '#FFD100', titleTextColor: '#3C2F00', imgbgcolor: '#FFF0C9', bodyBG: '#FFF0C9',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 4, name: 'Platinum', img: PlatinumActive, titlecolor: '#ACB2AC', titleTextColor: '#2C322E', imgbgcolor: '#DEE4DD', bodyBG: '#DEE4DD',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 5, name: 'Diamond', img: DiamondActive, titlecolor: '#B3ECF9', titleTextColor: '#00363E', imgbgcolor: '#EBFBFF', bodyBG: '#DFF6FB',
            Active: [{ status: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 6, name: 'Epic', img: EpicActive, titlecolor: '#8C49CA', titleTextColor: '#FAECFF', imgbgcolor: '#FAECFF', bodyBG: '#FAECFF',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 7, name: 'Legendary', img: LegendaryActive, titlecolor: '#C00021', titleTextColor: '#FFEDEB', imgbgcolor: '#FFE9EB', bodyBG: '#FFEDEB',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 8, name: 'Master', img: MasterActive, titlecolor: '#5656FF', titleTextColor: '#FFFBFF', imgbgcolor: '#F2EFFF', bodyBG: '#F2EFFF',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 9, name: 'Grandmaster', img: GrandmasterActive, titlecolor: '#0202A9', titleTextColor: '#E0E0FF', imgbgcolor: '#E0E0FF', bodyBG: '#E0E0FF',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 10, name: 'Lord', img: LordActive, titlecolor: '#006C44', titleTextColor: '#C0FFD7', imgbgcolor: '#C0FFD7', bodyBG: '#C0FFD7',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }], DeActive: true,

        },
        {
            id: 11, name: 'Creator', img: CreatorActive, titlecolor: '#212121', titleTextColor: '#FFFFFF', imgbgcolor: '#E3E3E3', bodyBG: '#E0E0E0',
            Active: [{ status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }, { status: false, name: false, xp: false, coin: false, min: false, max: false }],

        },
    ]

    React.useEffect(() => {
        CheckUser()
    }, [JoinCountRedux])
    const GetleagueListJoinsfunc = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let LeagueRes = await MakeApiClass.GetleagueListJoins()
        // console.log(LeagueRes)
        if (LeagueRes.code == 200) {
            Data = LeagueRes

        }
        return Data
        // console.log(LeagueRes)

    }
    const GetAppSetting = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let appRes = await MakeApiClass.appSetting()
        if (appRes.code == 200) {
            Data = appRes
        }
        return Data
    }
    const [AppSettingLocal, setAppSettingLocal] = React.useState('')
    const CheckUser = async () => {


        let LeagueRes = await GetleagueListJoinsfunc()
        console.log(LeagueRes)
        dispatch(SetUserLeagueData(LeagueRes.data.data))
        // console.log(LeagueRes.data.data.userInformation.leagueLevel)
        dispatch(SetLevelUserHeaderProgress(LeagueRes.data))

        let LevelUser = LeagueRes.data.data.userInformation.leagueLevel
        // console.log(LevelUser)


        let AppSettingRes = await GetAppSetting()
        // console.log(AppSettingRes)
        dispatch(SetAppSettingData(AppSettingRes.data.appSetting))

        setAppSettingLocal(AppSettingRes.data.appSetting)
        // AppSettingLocal = AppSettingRes.data.appSetting
        let RewardArray = AppSettingRes.data.appSetting.leagues
        // console.log(RewardArray)
        RewardArray.map(item => {
            // console.log(item.leagueLevel,LevelUser)
            if (item.leagueLevel == LevelUser) {
                // console.log(item)
                dispatch(SetRewardLeague(item))
            }
        })
        dispatch(SetGiftTopjoin(AppSettingRes.data.appSetting.giftTopjoin))
        // console.log(AppSettingRes.data.appSetting.giftTopjoin)
        // console.log(AppSettingRes.data.appSetting.leagues)
        let LeaguesArray = AppSettingRes.data.appSetting.leagues
        LeaguesArray.map(item => {
            if (item.leagueLevel == LevelUser) {
                dispatch(SetMyLeagueRank(item.leagueName))
                dispatch(SetLeagueLimitJoinProgress(item.max))
                dispatch(SetMyLeagueRankNumber(item.leagueLevel))
                CheckData(AppSettingRes.data.appSetting, item.leagueLevel)

            }
        })


    }
    const [updatedData, setUpdatedData] = React.useState(Data);

    const CheckData = (AppSettingData1, MyLeagueRankNumber) => {
        console.log(Data)
        setLoadState(true)
        Data = Data.map((item, i) => {

            if (item.id == 1) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[0]
                app1 = AppSettingData1.leagues[1]
                app2 = AppSettingData1.leagues[2]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };

            } else if (item.id == 2) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[3]
                app1 = AppSettingData1.leagues[4]
                app2 = AppSettingData1.leagues[5]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 3) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[6]
                app1 = AppSettingData1.leagues[7]
                app2 = AppSettingData1.leagues[8]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 4) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[9]
                app1 = AppSettingData1.leagues[10]
                app2 = AppSettingData1.leagues[11]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 5) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[12]
                app1 = AppSettingData1.leagues[13]
                app2 = AppSettingData1.leagues[14]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 6) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[15]
                app1 = AppSettingData1.leagues[16]
                app2 = AppSettingData1.leagues[17]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 7) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[18]
                app1 = AppSettingData1.leagues[19]
                app2 = AppSettingData1.leagues[20]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 8) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[21]
                app1 = AppSettingData1.leagues[22]
                app2 = AppSettingData1.leagues[23]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 9) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[24]
                app1 = AppSettingData1.leagues[25]
                app2 = AppSettingData1.leagues[26]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 10) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[27]
                app1 = AppSettingData1.leagues[28]
                app2 = AppSettingData1.leagues[29]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }
            else if (item.id == 11) {
                let app0, app1, app2
                app0 = AppSettingData1.leagues[30]
                app1 = AppSettingData1.leagues[31]
                app2 = AppSettingData1.leagues[32]

                return { ...item, Active: [{ status: false, ...app0 }, { status: false, ...app1 }, { status: false, ...app2 }] };
            }


            return item
        })
        // MyLeagueRankNumber = 33
        // let array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33]

        if (MyLeagueRankNumber == 1 || MyLeagueRankNumber == 2 || MyLeagueRankNumber == 3) {

            const updatedLevels = Data.map((item, i) => {
                if (item.id == 1) {

                    const updatedActive = item.Active.map((status, i) => {
                        console.log(MyLeagueRankNumber)
                        console.log(i)
                        if (i + 1 < MyLeagueRankNumber) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i + 1 == MyLeagueRankNumber) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        }
                        // if (i + 1 <= MyLeagueRankNumber) {

                        //     return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        // } 
                        else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }

                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);

        }
        else if (MyLeagueRankNumber == 4 || MyLeagueRankNumber == 5 || MyLeagueRankNumber == 6) {
            let InnerRank = MyLeagueRankNumber == 4 ? 0 : MyLeagueRankNumber == 5 ? 1 : 2

            const updatedLevels = Data.map((item, i) => {

                if (item.id == 2) {
                    const updatedActive = item.Active.map((status, i) => {
                        console.log(InnerRank)
                        console.log(i)
                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 2) {
                    const updatedActive = item.Active.map((status, i) => {

                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 7 || MyLeagueRankNumber == 8 || MyLeagueRankNumber == 9) {
            let InnerRank = MyLeagueRankNumber == 7 ? 0 : MyLeagueRankNumber == 8 ? 1 : 2
            const updatedLevels = Data.map((item, i) => {

                if (item.id == 3) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 3) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 10 || MyLeagueRankNumber == 11 || MyLeagueRankNumber == 12) {
            let InnerRank = MyLeagueRankNumber == 10 ? 0 : MyLeagueRankNumber == 11 ? 1 : 2

            const updatedLevels = Data.map((item, i) => {

                if (item.id == 4) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 4) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 13 || MyLeagueRankNumber == 14 || MyLeagueRankNumber == 15) {
            let InnerRank = MyLeagueRankNumber == 13 ? 0 : MyLeagueRankNumber == 14 ? 1 : 2

            const updatedLevels = Data.map((item, j) => {

                if (item.id == 5) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 5) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 16 || MyLeagueRankNumber == 17 || MyLeagueRankNumber == 18) {
            let InnerRank = MyLeagueRankNumber == 16 ? 0 : MyLeagueRankNumber == 17 ? 1 : 2

            const updatedLevels = Data.map((item, i) => {

                if (item.id == 6) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 6) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 19 || MyLeagueRankNumber == 20 || MyLeagueRankNumber == 21) {
            let InnerRank = MyLeagueRankNumber == 19 ? 0 : MyLeagueRankNumber == 20 ? 1 : 2

            const updatedLevels = Data.map((item, i) => {

                if (item.id == 7) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 7) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 22 || MyLeagueRankNumber == 23 || MyLeagueRankNumber == 24) {
            let InnerRank = MyLeagueRankNumber == 22 ? 0 : MyLeagueRankNumber == 23 ? 1 : 2

            const updatedLevels = Data.map((item, i) => {

                if (item.id == 8) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 8) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, name: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 25 || MyLeagueRankNumber == 26 || MyLeagueRankNumber == 27) {
            let InnerRank = MyLeagueRankNumber == 25 ? 0 : MyLeagueRankNumber == 26 ? 1 : 2
            const updatedLevels = Data.map((item, i) => {

                if (item.id == 9) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 9) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 28 || MyLeagueRankNumber == 29 || MyLeagueRankNumber == 30) {
            let InnerRank = MyLeagueRankNumber == 28 ? 0 : MyLeagueRankNumber == 29 ? 1 : 2
            const updatedLevels = Data.map((item, i) => {

                if (item.id == 10) {
                    const updatedActive = item.Active.map((status, i) => {
                        console.log(i)
                        console.log(InnerRank)

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 10) {
                    const updatedActive = item.Active.map((status, i) => {
                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        } else if (MyLeagueRankNumber == 31 || MyLeagueRankNumber == 32 || MyLeagueRankNumber == 33) {
            let InnerRank = MyLeagueRankNumber == 31 ? 0 : MyLeagueRankNumber == 32 ? 1 : 2
            const updatedLevels = Data.map((item, i) => {

                if (item.id == 11) {
                    const updatedActive = item.Active.map((status, i) => {

                        if (i < InnerRank) {
                            return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        } else if (i == InnerRank) {
                            return { status: 'Now', leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };

                        } else {
                            return { status: false, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                        }
                    });
                    return { ...item, Active: updatedActive, DeActive: false };


                } else if (item.id < 11) {

                    const updatedActive = item.Active.map((status, i) => {

                        return { status: true, leagueName: status.leagueName, xp: status.xp, min: status.min, max: status.max, coin: status.coin };
                    });
                    return { ...item, Active: updatedActive, DeActive: false };
                } else {
                    return { ...item, DeActive: true }
                }
                // return item;
            });
            setUpdatedData(updatedLevels);
        }

        // setTimeout(function () {
        //     var targetElement = document.getElementById('Creator');
        //     if (targetElement) {

        //         targetElement.scrollIntoView({ behavior: 'smooth' });
        //     } else {
        //         console.error('Element not found');
        //     }
        // }, 1000);


        setLoadState(false)

    }


    React.useEffect(() => {

        console.log(updatedData)

    }, [updatedData])




    // console.log(UserLeagueData)
    // console.log(AppSettingLocal)



    function abbreviate_number(num, fixed) {
        //   console.log(num)
        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return e;
    }

    return (

        LoadState
            ?
            <Loading />

            :
            <div className='AllReward'>
                <div className='AllRewardHeader'>
                    <div className='AllRewardHeaderInner'>
                        <div className='AllRewardHeaderInnerBack'>
                            <Link to="/Game" className='AllRewardHeaderInnerBack'>
                                <div className='AllRewardBack'>
                                    <img src={Back} />
                                </div>

                            </Link>
                            All Reward
                        </div>
                        <div className='GameHeaderProgress' >

                            <HeaderProgressBar />


                        </div>

                    </div>

                </div>
                <div className='AllRewardContent'>
                    <div className='AllRewardItems'>
                        {updatedData ?

                            updatedData.map((item, i) =>
                                item.DeActive == false ?
                                    // Deactive false

                                    <div className='BronzeReward' key={i}>
                                        <div className='BronzeTitleitem' >
                                            <div className='BronzeTitleitemText' id={item.name}
                                                style={{ backgroundColor: item.titlecolor, color: item.titleTextColor }} >{item.name}</div>
                                            <div className='BronzeTitleitemCup'
                                                style={{ backgroundColor: item.imgbgcolor, borderColor: item.titlecolor }}>
                                                <img src={item.img} /></div>
                                        </div>
                                        <div className='BronzeBodyitem'>
                                            <div className='BronzeBodyItemList' style={{ backgroundColor: item.bodyBG }}>

                                                {item.Active.map((ActiveItem, j) =>
                                                    ActiveItem.status == true ?
                                                        <div className='BronzeBodyItemListIn' key={j}>
                                                            <div className='BronzeBodyItemListIn1'>
                                                                <div className='BronzeBodyItemListIn1Title'>
                                                                    {ActiveItem.leagueName}

                                                                </div>
                                                                <div className='BronzeBodyItemListIn1Des'>
                                                                    {ActiveItem.min ? abbreviate_number(ActiveItem.min) : ActiveItem.min} to  {ActiveItem.max ? abbreviate_number(ActiveItem.max) : 0} Join
                                                                </div>
                                                            </div>
                                                            <div className='BronzeBodyItemListIn2'>
                                                                {ActiveItem.coin ? abbreviate_number(ActiveItem.coin) : 0} <img src={Coin} className='RewardCoin' /> +  {ActiveItem.xp ? abbreviate_number(ActiveItem.xp) : 0} <img src={Star} className='RewardStar' />
                                                            </div>
                                                        </div>
                                                        :
                                                        ActiveItem.status == 'Now' ?
                                                            <div className='BronzeBodyItemListIn' key={j}>
                                                                {
                                                                    ActiveItem.leagueName == 'Creator I' ?
                                                                        <div className='ActiveCreatorI'>
                                                                            <img src={Creator1Active} style={{ "marginRight": "4px", "marginLeft": "4px" }} />
                                                                            {ActiveItem.leagueName}
                                                                            <img src={Creator1Active} style={{ "marginRight": "4px", "marginLeft": "4px" }} />

                                                                        </div>
                                                                        : <>  <div className='BronzeBodyItemListIn1'>
                                                                            <div className='BronzeBodyItemListIn1Title'>
                                                                                {ActiveItem.leagueName}


                                                                            </div>
                                                                            <div className='BronzeBodyItemListIn1Des'>
                                                                                {ActiveItem ? ActiveItem.min > 1000 ? abbreviate_number(ActiveItem.min) : ActiveItem.min : ActiveItem.min} to  {ActiveItem ? abbreviate_number(ActiveItem.max) : 0} Join
                                                                            </div>
                                                                        </div>
                                                                            <div className='BronzeBodyItemListIn2'>
                                                                                {ActiveItem.coin ? abbreviate_number(ActiveItem.coin) : 0} <img src={Coin} className='RewardCoin' /> +  {ActiveItem.xp ? abbreviate_number(ActiveItem.xp) : 0} <img src={Star} className='RewardStar' />
                                                                            </div>
                                                                        </>
                                                                }



                                                            </div>
                                                            :
                                                            <div className='BronzeBodyItemListIn' key={j}>
                                                                {
                                                                    ActiveItem.leagueName == 'Creator I' ?
                                                                        <div className='DeactiveCreatorI'>
                                                                            <img src={Creator1DeActive} style={{ "marginRight": "4px", "marginLeft": "4px" }} />
                                                                            {ActiveItem.leagueName}
                                                                            <img src={Creator1DeActive} style={{ "marginRight": "4px", "marginLeft": "4px" }} />

                                                                        </div> :
                                                                        <>
                                                                            <div className='BronzeBodyItemListIn1'>
                                                                                <div className='BronzeBodyItemListIn1Title' style={{ color: "#616161" }}>
                                                                                    {ActiveItem.leagueName}
                                                                                </div>
                                                                                <div className='BronzeBodyItemListIn1Des' style={{ color: "#616161" }}>
                                                                                    {ActiveItem ? abbreviate_number(ActiveItem.min) : 0} to  {ActiveItem ? abbreviate_number(ActiveItem.max) : 0} Join
                                                                                </div>
                                                                            </div>
                                                                            <div className='BronzeBodyItemListIn2' style={{ color: "#616161" }}>
                                                                                {ActiveItem ? abbreviate_number(ActiveItem.coin) : 0} <img src={CoinDeactive} className='RewardCoin' /> + {ActiveItem ? abbreviate_number(ActiveItem.xp) : 0} <img src={StarDeactive} className='RewardStar' />
                                                                            </div>
                                                                        </>
                                                                }


                                                            </div>
                                                )}



                                            </div>

                                            <div className='LineContainer'>
                                                {

                                                    item.Active.map((ActiveItem, i) =>
                                                        i == 0 ?


                                                            ActiveItem.status == true ?
                                                                item.name == 'Creator' ?
                                                                    <div className='VerticalLine  VerticalLineFirst CreatorLineEmpty' style={{ borderColor: item.titlecolor }} key={i}>
                                                                        <div className='VerticalLineItem' style={{ backgroundColor: item.titlecolor }}>

                                                                            <DoneIcon className='VerticalLineItemIcon' style={{ color: item.titleTextColor }} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='VerticalLine  VerticalLineFirst' style={{ borderColor: item.titlecolor }} key={i}>
                                                                        <div className='VerticalLineItem' style={{ backgroundColor: item.titlecolor }}>

                                                                            <DoneIcon className='VerticalLineItemIcon' style={{ color: item.titleTextColor }} />
                                                                        </div>
                                                                    </div>
                                                                :
                                                                ActiveItem.status == 'Now' ?
                                                                    item.name == 'Creator' ?
                                                                        <div className='VerticalLineEmpty VerticalLineFirst CreatorLineEmpty' style={{ borderColor: item.titlecolor }} key={i}>

                                                                            <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                        </div>
                                                                        :
                                                                        <div className='VerticalLineEmpty VerticalLineFirst ' style={{ borderColor: item.titlecolor }} key={i}>

                                                                            <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                        </div>
                                                                    :
                                                                    <div className='VerticalLineEmpty VerticalLineFirst' style={{ borderColor: item.titlecolor }} key={i}>
                                                                        1111
                                                                        <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                    </div>

                                                            :
                                                            i == 1 ?
                                                                ActiveItem.status == true ?

                                                                    item.name == 'Creator' ?
                                                                        <div className='VerticalLine  CreatorLineEmpty' style={{ borderColor: item.titlecolor }} key={i}>
                                                                            <div className='VerticalLineItem LastPaddForInnerActive' style={{ backgroundColor: item.titlecolor }}>

                                                                                <DoneIcon className='VerticalLineItemIcon' style={{ color: item.titleTextColor }} />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className='VerticalLine VerticalLineFirst ' style={{ borderColor: item.titlecolor }} key={i}>
                                                                            <div className='VerticalLineItem' style={{ backgroundColor: item.titlecolor }}>
                                                                                {/* 456 */}
                                                                                <DoneIcon className='VerticalLineItemIcon' style={{ color: item.titleTextColor }} />
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                    ActiveItem.status == 'Now' ?
                                                                        item.name == 'Creator' ?

                                                                            <div className='VerticalLineEmpty LastPadding' style={{ borderColor: item.titlecolor }} key={i}>
                                                                                {/* 789 */}
                                                                                <div className='VerticalLineItemEmpty LastPaddForInner' style={{ borderColor: item.titlecolor, }}></div>

                                                                            </div>

                                                                            :
                                                                            <div className='VerticalLineEmpty VerticalLineCenter ' style={{ borderColor: item.titlecolor, padding: '24px 0 24px 0' }} key={i}>

                                                                                <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                            </div>
                                                                        :
                                                                        item.name == 'Creator' ?
                                                                            <div className='VerticalLineEmpty  CreatorLineEmpty' style={{ borderColor: item.titlecolor }} key={i}>

                                                                                <div className='VerticalLineItemEmpty LastPaddForInner' style={{ borderColor: item.titlecolor }}></div>

                                                                            </div>
                                                                            :
                                                                            <div className='VerticalLineEmpty' style={{ borderColor: item.titlecolor, padding: '24px 0 24px 0' }} key={i}>

                                                                                <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                            </div>



                                                                :

                                                                i == 2 ?
                                                                    item.name == 'Creator' ?
                                                                        ActiveItem.status == true ?
                                                                            <div className='VerticalLineFinishFilled VerticalLineLast VerticalLineFirst CreatorLine' style={{ borderColor: item.titlecolor }} key={i}>
                                                                                <div className='VerticalLineItem' style={{ backgroundColor: item.titlecolor }}>

                                                                                    <DoneIcon className='VerticalLineItemIcon' style={{ color: item.titleTextColor }} />
                                                                                </div>
                                                                            </div>
                                                                            : ActiveItem.status == 'Now' ?

                                                                                <div className='VerticalLineFinish VerticalLineFirst CreatorLine' style={{ borderColor: item.titlecolor }} key={i}>

                                                                                    <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                                </div>

                                                                                :

                                                                                <div className='VerticalLineFinishDisable VerticalLineLast CreatorLine' style={{ borderColor: item.titlecolor }} key={i}>

                                                                                    <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                                </div>
                                                                        :
                                                                        ActiveItem.status == true ?
                                                                            <div className='VerticalLine VerticalLineLast VerticalLineFirst' style={{ borderColor: item.titlecolor }} key={i}>
                                                                                <div className='VerticalLineItem' style={{ backgroundColor: item.titlecolor }}>

                                                                                    <DoneIcon className='VerticalLineItemIcon' style={{ color: item.titleTextColor }} />
                                                                                </div>
                                                                            </div>
                                                                            : ActiveItem.status == 'Now' ?

                                                                                <div className='VerticalLineEmpty VerticalLineFirst' style={{ borderColor: item.titlecolor }} key={i}>

                                                                                    <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                                </div>

                                                                                :

                                                                                <div className='VerticalLineEmpty VerticalLineLast' style={{ borderColor: item.titlecolor }} key={i}>

                                                                                    <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                                                                </div>


                                                                    : null


                                                    )


                                                }
                                                {/* <div className='VerticalLine  VerticalLineFirst' style={{ borderColor: item.titlecolor }}>
                                                <div className='VerticalLineItem' style={{ backgroundColor: item.titlecolor }}>
                                                    <DoneIcon className='VerticalLineItemIcon' style={{ color: item.titleTextColor }} />
                                                </div>
                                            </div>
                                            <div className='VerticalLineEmpty' style={{ borderColor: item.titlecolor }}>

                                                <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                            </div>
                                            <div className='VerticalLineEmpty VerticalLineLast' style={{ borderColor: item.titlecolor }}>

                                                <div className='VerticalLineItemEmpty' style={{ borderColor: item.titlecolor }}></div>

                                            </div> */}
                                            </div>


                                        </div>
                                    </div>

                                    :
                                    // Deactive true
                                    <div className='BronzeReward' key={i}>
                                        <div className='BronzeTitleitem' >
                                            <div className='BronzeTitleitemText'
                                                style={{ backgroundColor: '#F5F5F5', color: '#616161' }} >{item.name}</div>
                                            <div className='BronzeTitleitemCup'
                                                style={{ backgroundColor: "#F5F5F5", borderColor: '#BDBDBD' }}>
                                                <img src={Lock} /></div>
                                        </div>
                                        <div className='BronzeBodyitem'>
                                            <div className='BronzeBodyItemList' style={{ backgroundColor: '#F5F5F5' }}>
                                                {item.Active.map((ActiveItem, j) =>
                                                    <div className='BronzeBodyItemListIn' key={j}>


                                                        {
                                                            ActiveItem.leagueName == 'Creator I' ?
                                                                <div className='DeactiveCreatorI'>
                                                                    <img src={Creator1DeActive} style={{ "marginRight": "4px", "marginLeft": "4px" }} />
                                                                    {ActiveItem.leagueName}
                                                                    <img src={Creator1DeActive} style={{ "marginRight": "4px", "marginLeft": "4px" }} />

                                                                </div>
                                                                : <>
                                                                    <div className='BronzeBodyItemListIn1'>
                                                                        <div className='BronzeBodyItemListIn1Title' style={{ color: "#616161" }}>
                                                                            {ActiveItem.leagueName}
                                                                        </div>
                                                                        <div className='BronzeBodyItemListIn1Des' style={{ color: "#616161" }}>
                                                                            {ActiveItem ? abbreviate_number(ActiveItem.min) : 0} to  {ActiveItem ? abbreviate_number(ActiveItem.max) : 0} Join
                                                                        </div>
                                                                    </div>
                                                                    <div className='BronzeBodyItemListIn2'>
                                                                        {ActiveItem ? abbreviate_number(ActiveItem.coin) : 0} <img src={CoinDeactive} className='RewardCoin' /> + {ActiveItem ? abbreviate_number(ActiveItem.xp) : 0} <img src={StarDeactive} className='RewardStar' />
                                                                    </div>
                                                                </>

                                                        }




                                                    </div>
                                                )}




                                            </div>
                                            <div className='LineContainer'>
                                                {

                                                    item.Active.map((ActiveItem, i) =>
                                                        i == 0 ?

                                                            item.name == 'Creator' ?
                                                                <div className='VerticalLineEmpty CreatorLineEmpty' key={i} style={{ borderColor: '#BDBDBD', padding: '44px 0 24px 0' }}>

                                                                    <div className='VerticalLineItemEmpty' style={{ borderColor: '#BDBDBD' }}></div>

                                                                </div>
                                                                :

                                                                <div className='VerticalLineEmpty VerticalLineFirst' key={i} style={{ borderColor: '#BDBDBD' }}>

                                                                    <div className='VerticalLineItemEmpty' style={{ borderColor: '#BDBDBD' }}></div>

                                                                </div>

                                                            :
                                                            i == 1 ?
                                                                item.name == 'Creator' ?
                                                                    <div className='VerticalLineEmpty ' key={i} style={{ borderColor: '#fff', }}>
                                                                        
                                                                        <div className='VerticalLineItemEmpty' style={{ borderColor: '#BDBDBD' }}></div>

                                                                    </div>
                                                                    :
                                                                    <div className='VerticalLineEmpty' key={i} style={{ borderColor: '#BDBDBD', padding: '24px 0 24px 0' }}>

                                                                        <div className='VerticalLineItemEmpty' style={{ borderColor: '#BDBDBD' }}></div>

                                                                    </div>

                                                                :
                                                                i == 2 ?
                                                                    item.name == 'Creator' ?

                                                                        <div className='VerticalLineFinishDisable VerticalLineLast CreatorLine' style={{ borderColor: '#BDBDBD' }}>

                                                                            <div className='VerticalLineItemEmpty' style={{ borderColor: '#BDBDBD' }}></div>

                                                                        </div>

                                                                        :
                                                                        <div className='VerticalLineEmpty VerticalLineLast' style={{ borderColor: '#BDBDBD' }}>

                                                                            <div className='VerticalLineItemEmpty' style={{ borderColor: '#BDBDBD' }}></div>

                                                                        </div>

                                                                    : null


                                                    )


                                                }

                                            </div>


                                        </div>
                                    </div>
                            )

                            : null}



                    </div>
                </div>

            </div>
    );
}
export default AllReward