import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
// import Tooltip from '@mui/material/Tooltip';
import HeadImg from './../../src/Public/Image/Game/Group 26086606.svg'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { makeStyles } from '@mui/core/styles';


export default function LinearDeterminate() {
    const [progress, setProgress] = React.useState(0);
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const LevelUserHeaderProgress = useSelector((state) => state.Game.LevelUserHeaderProgress)

    useEffect(() => {
        console.log(LevelUserHeaderProgress)
        if (LevelUserHeaderProgress) {
            let Limit = LevelUserHeaderProgress.user.xpLimit
            let Count = LevelUserHeaderProgress.user.xpCount


            let ProgressFinal = (Count * 100) / Limit
            // console.log(ProgressFinal)
            setProgress(ProgressFinal)

        }


    }, [LevelUserHeaderProgress])

    // React.useEffect(() => {

    //   const timer = setInterval(() => {
    //     setProgress((oldProgress) => {
    //       if (oldProgress === 100) {
    //         return 0;
    //       }
    //       const diff = Math.random() * 10;
    //       return Math.min(oldProgress + diff, 100);
    //     });
    //   }, 2000);

    //   return () => {
    //     clearInterval(timer);
    //   };
    // }, []);
    // console.log(UserLeagueData)

    // const classes = useStyles();
    // const useStyles = makeStyles(theme => ({
    //     customTooltip: {
    //       // I used the rgba color for the standard "secondary" color
    //       backgroundColor: 'rgba(220, 0, 78, 0.8)',
    //     },
    //     customArrow: {
    //       color: 'rgba(220, 0, 78, 0.8)',
    //     },
    //   }));
    return (
        LevelUserHeaderProgress.user ?
            <>
                <Box sx={{ width: '100%', height: '24px', marginLeft: '0px', 'rotate': '180deg !important', 'marginRight': '-3px', 'zIndex': '0' }}>
                    <Tooltip
                        enterTouchDelay={0}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: '1.125rem', backgroundColor: '#fff', color: '#212121', boxShadow: ' 0px 2px 6.4px 0px #00000040'
                                },
                            },
                            arrow: {
                                sx: {
                                    color: '#ffff'
                                },

                            }
                        }}

                        title={<React.Fragment>
                            <div className='InTooltip'>
                                <div className='InTooltip1'>To next level</div>
                                <div className='InTooltip12'>
                                    <b>{LevelUserHeaderProgress.user.xpCount ? LevelUserHeaderProgress.user.xpCount.toLocaleString() : 0}</b> <b>{'/'}</b> <b>{LevelUserHeaderProgress.user.xpLimit.toLocaleString()}</b>{' '}
                                </div>
                            </div>


                        </React.Fragment>}

                        arrow>




                        <LinearProgress variant="determinate" value={progress}
                            sx={{
                                backgroundColor: 'white',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#008EFF'
                                }
                            }} style={{ 'border': '1px solid #E0E0E0', 'height': '24px', 'backgroundColor': '#F5F5F5', 'color': '#BF8970 !important', 'borderTopRightRadius': '10px', 'borderBottomRightRadius': '10px', 'rotate': '180deg !important' }} />


                    </Tooltip>
                </Box>
                <div className='HeaderProgressBarImgContainer'>
                    <div className='HeaderProgressBarAbsoluteNumber'>
                        {LevelUserHeaderProgress.user.xpLevel}

                    </div>
                    <img src={HeadImg} className='HeaderProgressBarImg' />
                </div>
            </>
            : 'loading'
    );
}
